import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCompany, selectInvoices } from '../../store/company/company.selector'
import { downLoadInvoice, generateInvoice, searchInvoices } from '../../store/company/company.actions'
import { Button, Form, Modal, Pagination, Row, Tooltip } from 'antd'
import CustomSearch from '../../components/CustomSearch/CustomSearch'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import InvoiceCell from '../../components/Invoice/InvoiceCell'
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { actionTypes } from '../../store/company/company.types'

const sortProperties = [
  { label: 'Fecha', property: 'fromDate' }
]

const { confirm } = Modal

const Invoices = () => {
  const dispatch = useDispatch()
  const currentCompany = useSelector(selectCompany)
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 })
  const [searchForm] = Form.useForm()
  const invoices = useSelector(selectInvoices)
  const [loading] = useIsLoading([actionTypes.DOWNLOAD_INVOICE])

  const refresh = useCallback(({ active, deleted, last, search, searchProperty, sortOrder, sortProperty, from, to } = {}) => {
    dispatch(searchInvoices({
      companyId: currentCompany.id,
      active,
      deleted,
      last,
      search,
      searchProperty,
      sortOrder,
      sortProperty,
      from: from?.unix(),
      to: to?.unix(),
      ...pagination
    }))
  }, [currentCompany.id, dispatch, pagination])

  const handleGenerateInvoice = () => {
    confirm({
      title: 'Generar corte',
      content: '¿Está seguro que desea hacer el corte de la empresa al día de hoy y generar la factura? Esta acción no se puede deshacer.',
      onOk: () => dispatch(generateInvoice({ companyId: currentCompany.id }))
    })
  }

  const handleDownload = (invoiceId) => {
    dispatch(downLoadInvoice({ companyId: currentCompany.id, invoiceId }))
  }

  const onPageChange = (page, pageSize) => setPagination({ page, pageSize })

  return (
    <div className='gx-main-content'>
      <div className='gx-app-module'>
        <div className='gx-module-box-content'>
          <LoadingIndicator loading={loading} />
          <br />
          <div className='gx-module-box-topbar flex-header'>
            <CustomSearch
              form={searchForm}
              onChange={refresh}
              sortProperties={sortProperties}
              search={false}
            />
            <Row justify='end' style={{ flexGrow: 1, paddingRight: 30 }}>
              <Tooltip title='Desde el ultimo corte hasta hoy' placement='topLeft'>
                {(currentCompany?.finishedCount === 0 && currentCompany?.surveyFinishedCount === 0) ? <div style={{ width: '137px' }} /> :
                  <Button
                    size='large'
                    className='green-button'
                    type='primary'
                    aria-label='add' onClick={handleGenerateInvoice}
                  >
                    Generar Corte
                  </Button>
                }
              </Tooltip>
            </Row>
          </div>
          <CustomScrollbars className='scroll-tab'>
            {
              invoices.items.length === 0
                ? (
                  <div className='gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center'>
                    No se encontraron candidatos con los filtros específicados
                  </div>
                )
                : invoices.items.map((invoice, index) => {
                  index++
                  return (
                    <InvoiceCell index={index} invoice={invoice} key={`invoice_${invoice.id}`} handleDownload={handleDownload} />
                  )
                })
            }
          </CustomScrollbars>
          <Row justify='end' className='gx-border-top gx-pr-5 gx-pt-4 invoice-pagination-tab'>
            <Pagination
              showSizeChanger
              defaultCurrent={1}
              onChange={onPageChange}
              total={invoices?.total || 0}
              current={invoices?.page || 0}
              pageSize={invoices?.pageSize || 0}
              hideOnSinglePage={invoices.total === 0}
              showTotal={(total, range) => `${range[0]} a ${range[1]} de ${total}`}
            />
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Invoices
