import axios from 'axios'
import { API_URL } from '../app.settings'
import { store } from '../store/'
import { logout, showLogout } from '../store/auth/logout.actions'
import { selectToken } from '../store/auth/auth.selectors'
import {clearAssignments} from "../store/assignments/assignments.actions";
import {clearCandidates} from "../store/candidates/candidates.actions";
import {clearCompanies} from "../store/company/company.actions";
import {clearUsers} from "../store/users/users.actions";

const instance = axios.create({
  baseURL: API_URL,
  timeout: 10000
})

instance.interceptors.request.use(
  async config => {
    const state = store.getState()
    const accessToken = selectToken(state)

    if (accessToken) {
      config.headers.Authorization = `bearer ${accessToken}`
    }

    return config
  },
  error => Promise.reject(error)
)

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401 && error.response.config.url !== '/auth/login/back-office') {
      // alert('La sesión ha expirado')
      store.dispatch(clearUsers())
      store.dispatch(clearCompanies())
      store.dispatch(clearCandidates())
      store.dispatch(clearAssignments())
      store.dispatch(showLogout())
      store.dispatch(logout())
    }
    return Promise.reject(error)
  })

export const downloadFile = async (url, params) => {
  const response = await $http.get(url, { responseType: 'blob', headers: { accept: 'application/octet-stream' }, params })
  const data = response.data
  if (!(data instanceof Blob)) return

  const filename = response.headers['x-file-name']
  const blob = new Blob([data], { type: 'application/pdf' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = filename
  link.click()
}

export const $http = instance
