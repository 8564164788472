import { actionTypes } from './settings.types'
import { executeAction } from '../app/app.actions'

export const setThemeType = (themeType) => dispatch => {
  const process = () => themeType
  dispatch(executeAction(actionTypes.THEME_TYPE, process))
}

export const setThemeColor = (themeColor) => dispatch => {
  const process = () => themeColor
  dispatch(executeAction(actionTypes.THEME_COLOR, process))
}

export const onNavStyleChange = (navStyle) => dispatch => {
  const process = () => navStyle
  dispatch(executeAction(actionTypes.NAV_STYLE, process))
}

export const onLayoutTypeChange = (layoutType) => dispatch => {
  const process = () => layoutType
  dispatch(executeAction(actionTypes.LAYOUT_TYPE, process))
}

export const switchLanguage = (locale) => dispatch => {
  const process = () => locale
  dispatch(executeAction(actionTypes.SWITCH_LANGUAGE, process))
}

export const updateTopBarTitle = (title = '') => dispatch => {
  const process = () => title
  dispatch(executeAction(actionTypes.UPDATE_TITLE, process))
}
