export const actionTypes = {
  ADD_COMMENTS: 'candidates/comments/add',
  ADD_JOB: 'candidates/jobs/add',
  ADD_JUDICIAL: 'candidates/judicial/add',
  ADD_SINDICATOS: 'candidates/sindicatos/add',
  CLEAR_CANDIDATES: 'candidates/clear',
  CONFIRM_JOB: 'candidates/jobs/confirm',
  DIFFER_JOB: 'candidates/jobs/differ',
  DOWNLOAD_REPORT: 'candidates/report',
  FINISH_CANDIDATE: 'candidates/finish',
  GET_JOBS: 'candidates/jobs/get',
  PROCESS_CANDIDATE: 'candidates/process',
  REASSIGN_CANDIDATE: 'candidate/reassign',
  SEARCH_CANDIDATES: 'candidates/search',
  SINGLE_CANDIDATE: 'candidates/single/get'
}
