import React, {useEffect} from 'react'
import moment from 'moment'
import Widget from '../../components/Widget'
import {Avatar, Card, Tag} from 'antd'
import { useParams } from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {getAssignmentAnswers} from "../../store/assignments/assignments.actions";
import {selectAssignmentAnswers} from "../../store/assignments/assignments.selector";
import CustomScrollbars from "../../components/Scrolls/CustomScrollbars";

const dateFormat = 'DD/MM/YYYY'

const AssignmentResult = () => {

  const { assignmentId } = useParams()
  const assignmentAnswers = useSelector(selectAssignmentAnswers)
  const dispatch = useDispatch()

  const init = () => {
    console.log(assignmentId)
    dispatch(getAssignmentAnswers({ assignmentId }))
  }
  useEffect(init, [])

  const getTagByStatus = (value) => {
    return (
      <Tag style={{textAlign: 'left', color:'black'}} color='green' className='status-tag'>{value}</Tag>//randomHexColorByString(value)
    )
  }

  return (
    <div>
      {assignmentAnswers && <>
      <div className='gx-profile-banner'>
        <div className='gx-profile-container'>
          <div className='gx-profile-banner-top'>
            <div className='gx-profile-banner-top-left gx-pl-5 gx-pt-5'>
              <div className='gx-profile-banner-avatar'>
                <Avatar className='gx-size-90 gx-fs-2xl' alt='...' style={{ backgroundColor: '#9368B7', verticalAlign: 'middle', marginRight: 15 }} size='large'>
                  {assignmentAnswers.assignment?.name[0].toUpperCase()}
                </Avatar>
              </div>
              <div className='gx-profile-banner-avatar-info'>
                <h2 className='gx-mb-0 gx-fs-xxl gx-font-weight-light'>#{assignmentAnswers.assignment?.id} </h2>
                <div style={{display: 'flex'}} ><h3>{assignmentAnswers.assignment?.name}</h3> <div style={{width:'10px'}} />|<div style={{width:'10px'}} /><h3>{assignmentAnswers.assignment?.position}</h3></div>
                <p className='gx-mb-0 gx-fs-lg'>Ingreso: {moment(assignmentAnswers.assignment?.startDate).format(dateFormat)} - Baja: {moment(assignmentAnswers.assignment?.endDate).format(dateFormat)}</p>
              </div>
            </div>
            <div className='gx-profile-banner-top-right gx-pr-5'>
              <ul className='gx-follower-list gx-pt-5'>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '20px'}}>
                  <span className='gx-follower-title gx-fs-2xl gx-font-weight-medium'>
                    <i className={'gx-icon-btn icon icon-phone'} />
                  </span>
                  <span className='gx-follower-title gx-fs-2xl gx-font-weight-medium'>
                    {assignmentAnswers.assignment?.contactPhone}
                  </span>
                </div>
              </ul>
            </div>
          </div>
          <div style={{paddingLeft: '32px'}}>
            <p><b>Comentario:</b> {assignmentAnswers.assignment?.comment}</p>
          </div>
        </div>
      </div>
      <CustomScrollbars className='answers-scroll'>
        <div className='gx-profile-content'>
          <Widget styleName='gx-card-profile'>
            {assignmentAnswers.assignment?.noAnswer &&
            <>
              <div className='ant-card-head'>
                <span className='ant-card-head-title gx-mb-2' style={{paddingLeft: '0px'}}>Sin Respuesta</span>
              </div>
              <p>
                <b>Comentario: </b>{assignmentAnswers.assignment?assignmentAnswers.assignment.noAnswerComment:'Sin Comentario'}
              </p>
            </>
            }
            {!assignmentAnswers.assignment?.noAnswer &&
            <>
              <div className='ant-card-head'>
                <span className='ant-card-head-title gx-mb-2' style={{paddingLeft: '0px'}}>Respuestas</span>
              </div>
              <div>
                {assignmentAnswers.answers?.sort((a, b) => (a.position > b.position) ? 1 : -1).map((answer, index) =>
                  <Card
                    type="inner"
                    title={`${index+1}. ${answer.question}`}
                    key={index}
                  >
                    {((answer.type === 'radio')||(answer.type === 'check-box')) &&
                    <div style={{display: 'flex', flexWrap: 'wrap', flexFlow: 'column', textAlign: 'left'}}>{answer.answers.map(ans =>
                      <>{getTagByStatus(ans)}</>
                    )}
                      {answer.options.map(ans =>
                        <Tag style={{textAlign: 'left'}} className='status-tag'>{ans}</Tag>
                      )}
                    </div>
                    }
                    { answer.type === 'text' &&
                    <div>{answer.answer !== 'Sin respuesta'?answer.answer: <span style={{color: 'red'}}>Sin Respuesta</span>}</div>
                    }
                    { answer.type === 'range' &&
                    <>{answer.answer !=='0'? <b>{answer.answer}/{answer.limit}</b> : <span style={{color: 'red'}}>Sin Respuesta</span> }</>
                    }
                  </Card>
                )}
              </div>
            </>
            }
          </Widget>
        </div>
      </CustomScrollbars></>}
    </div>
  )
}

export default AssignmentResult
