import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import 'assets/vendors/style'
import 'styles/wieldy.less'
import { PersistGate } from 'redux-persist/integration/react'
import { history, persistor, store } from './store'
import Routes from './routes/Routes'

const NextApp = () =>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </PersistGate>
  </Provider>

export default NextApp
