import { actionTypes } from './auth.types'
import { extract, successState } from '../app/app.actions'

const initialState = {
  accessToken: null,
  user: null,
  admin: false,
  supervisor: false,
  investigator: false,
  logout: false
}

const authorization = (state = initialState, { type, data }) => {
  switch (type) {
    case successState(actionTypes.LOGIN):
      return {
        ...state,
        accessToken: extract(data.token),
        user: extract(data.user),
        admin: extract(data.admin, false),
        supervisor: extract(data.supervisor, false),
        investigator: extract(data.investigator, false)
      }
    case successState(actionTypes.UPDATE_USER):
      return {
        ...state,
        user: data
      }
    case successState(actionTypes.HIDE_LOGOUT):
      return {
        ...state,
        logout: false
      }
    case successState(actionTypes.SHOW_LOGOUT):
      return {
        ...state,
        logout: true
      }
    case successState(actionTypes.LOGOUT):
      return initialState
    default:
      return state
  }
}

export default authorization
