import React, { useEffect, useState } from 'react'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import { Avatar, Badge, Button, Modal, Tabs, Row, Col, Form, notification } from 'antd'
import { useLocation } from 'react-router-dom'
import CandidateForm from '../../components/Candidate/CandidateForm'
import CandidateJobsForm from '../../components/Candidate/CandidateJobsForm'
import CandidateJobCell from '../../components/Candidate/CandidateJobCell'
import { useDispatch } from 'react-redux'
import { updateTopBarTitle } from '../../store/settings/settings.actions'
import moment from 'moment'
import { searchCompanies } from '../../store/company/company.actions'

const TabPane = Tabs.TabPane

const SingleCandidate = () => {
  const [showModal, setShowModal] = useState(false)
  const [count, setCount] = useState(0)
  const [data, setData] = useState([])
  const [jobId, setJobId] = useState(10)

  const location = useLocation()
  const [form] = Form.useForm()

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const dispatch = useDispatch()
  const confirm = Modal.confirm

  const init = () => {
    dispatch(updateTopBarTitle('Datos del candidato'))
    dispatch(searchCompanies({ pageSize: 10000 }))
  }
  useEffect(init, [])

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description
    })
  }

  const deleteCandidateJob = (id) => {
    const newData = [...data]
    const index = newData.map((e) => { return e.id }).indexOf(id)
    if (index >= 0) {
      newData.splice(index, 1)
    }
    setData([...newData])
    setCount(count - 1)
  }

  const showDeleteConfirm = (folio) => {
    confirm({
      title: 'Desea Eliminar este Trabajo?',
      content: '',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk () {
        deleteCandidateJob(folio)
        openNotificationWithIcon('info', 'Trabajo Eliminado', '')
      },
      onCancel () {
      }
    })
  }

  const onFinish = (values) => {
    values.id = jobId
    values.start = moment(values.startObject).format('DD/MM/YYYY')
    values.end = values.endObject ? moment(values.endObject).format('DD/MM/YYYY') : '1/1/2020'
    setJobId(jobId + 1)
    setData([...data, values])
    setCount(count + 1)
    openNotificationWithIcon('success', 'Trabajo Agregado', '')
    form.resetFields()
  }

  const clickOkModal = () => {
    form.validateFields().then(() => {
      if (form.getFieldsError().filter(({ errors }) => errors.length).length > 0) {
        return
      }
      toggleModal()
      form.submit()
    })
  }

  const isProcesed = location.pathname.includes('in-process')
  return (
    <div>
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <CustomScrollbars className='candidate-tabs-scroll'>
              <div style={{ padding: '28px' }}>
                <Tabs type='card'>
                  <TabPane tab='Informacion Basica' key='1'>
                    <CandidateForm isProcesed={isProcesed} />
                  </TabPane>
                  <TabPane
                    tab={
                      <div>
                        <span style={{ marginRight: '25px' }}>Empleos</span>
                        <Badge style={{ marginLeft: '5px', marginRight: 5 }} count={count}>
                          <div className='head-example' />
                        </Badge>
                      </div>
                    } key='2'
                  >
                    <CustomScrollbars className='candidate-jobs-scroll'>
                      {
                        data.length === 0
                          ? (
                            <div className='gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center'>
                              <div>
                                <Row>
                                  <Col span={6} />
                                  <Col span={6}>
                                    <Avatar onClick={toggleModal} style={{ backgroundColor: 'green', verticalAlign: 'middle', fontSize: 'xx-large' }} size='large'>
                                      +
                                    </Avatar>
                                  </Col>
                                  <Col span={12}>
                                    Agregar Empleo
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          )
                          : (
                            <div>
                              {data.map((job, index) => <CandidateJobCell isProcesed={isProcesed} job={job} key={index} handleDelete={showDeleteConfirm} />)}
                              <div style={{ height: '1em' }} />
                              <div className='gx-contact-item'>
                                <div className='gx-module-list-icon'>
                                  <div className='gx-ml-2 gx-d-none gx-d-sm-flex'>
                                    <Avatar onClick={toggleModal} style={{ backgroundColor: 'green', verticalAlign: 'middle', fontSize: 'xx-large' }} size='large'>
                                      +
                                    </Avatar>
                                  </div>
                                </div>
                                <div className='gx-module-list-info gx-contact-list-info'>
                                  <div className='gx-module-contact-content'>
                                    <p className='gx-mb-1'>
                                      <span className='gx-text-truncate gx-contact-name'> Agregar Empleo </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                      }
                    </CustomScrollbars>
                  </TabPane>
                </Tabs>
              </div>
            </CustomScrollbars>
          </div>
        </div>
      </div>
      <Modal
        centered
        width={1024}
        title='Agregar Empleo'
        visible={showModal}
        className='warning-modal'
        onCancel={toggleModal}
        footer={[
          <Button key='Cancelar' onClick={toggleModal}> Cancelar </Button>,
          <Button
            key='Registrar' type='primary' onClick={clickOkModal}
          >
            Registrar
          </Button>
        ]}
      >
        <p> <CandidateJobsForm form={form} onFinish={onFinish} /> </p>
      </Modal>
    </div>
  )
}

export default SingleCandidate
