import React from 'react'
import { Col, Form, Input, Row } from 'antd'
import { inputRequired } from '../../util/forms.utils'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}

const BranchForm = (props) => {

  const InputGroup = Input.Group
  const isProcesed = props.isProcesed
  const form = props.form

  return (
    <Form
      layout='horizontal'
      form={form}
      name='register'
      labelAlign='left'
      onFinish={props.onFinish}
      {...formItemLayout}
      scrollToFirstError
    >
      <Form.Item
        name='name'
        label='Nombre'
        rules={[inputRequired]}
        labelCol={{ sm: { span: 4 } }}
        wrapperCol={{ sm: { span: 20 } }}
      >
        <Input readOnly={isProcesed} placeholder='Nombre' />
      </Form.Item>

      <Form.Item
        name='address'
        label='Direccion'
        rules={[inputRequired]}
        labelCol={{ sm: { span: 4 } }}
        wrapperCol={{ sm: { span: 20 } }}
      >
        <Input readOnly={isProcesed} placeholder='Direccion' />
      </Form.Item>

      <Form.Item
        name='contact'
        label='Contacto'
        rules={[inputRequired]}
        labelCol={{ sm: { span: 4 } }}
        wrapperCol={{ sm: { span: 20 } }}
      >
        <Input readOnly={isProcesed} placeholder='Contacto' />
      </Form.Item>

      <InputGroup>
        <Row>
          <Col sm={12}>
            <Form.Item
              name='phone'
              label='Telefono'
              rules={[inputRequired]}
            >
              <Input readOnly={isProcesed} placeholder='5555-5555' />
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item
              name='mail'
              label='Email'
              rules={[inputRequired]}
            >
              <Input readOnly={isProcesed} placeholder='email@ejemplo.com' />
            </Form.Item>
          </Col>
        </Row>
      </InputGroup>

    </Form>
  )
}

export default BranchForm
