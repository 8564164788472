import { extract, successState } from '../app/app.actions'
import { actionTypes } from './candidates.types'

const initialState = {
  candidates: { page: 1, items: [], pageSize: 10, total: 0, count: 0 },
  currentCandidate: null,
  jobs: []
}

const CandidatesReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case successState(actionTypes.SEARCH_CANDIDATES): {
      return {
        ...state,
        candidates: extract(data, initialState.candidates)
      }
    }
    case successState(actionTypes.SINGLE_CANDIDATE):
    case successState(actionTypes.ADD_JUDICIAL):
    case successState(actionTypes.ADD_SINDICATOS):
    case successState(actionTypes.ADD_COMMENTS):
    case successState(actionTypes.FINISH_CANDIDATE):
    case successState(actionTypes.PROCESS_CANDIDATE): {
      return {
        ...state,
        currentCandidate: data
      }
    }
    case successState(actionTypes.CONFIRM_JOB):
    case successState(actionTypes.DIFFER_JOB):
    case successState(actionTypes.ADD_JOB):
    case successState(actionTypes.GET_JOBS): {
      return {
        ...state,
        jobs: data
      }
    }
    case successState(actionTypes.CLEAR_CANDIDATES):
      return initialState
    default:
      return state || initialState
  }
}

export default CandidatesReducer
