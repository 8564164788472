import React, { useEffect } from 'react'
import { Col, Form, Input, Row } from 'antd'
import moment from 'moment'

const { TextArea, Group } = Input

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 }
  }
}

const CandidateForm = ({ candidate }) => {
  const [form] = Form.useForm()

  useEffect(() => {
    const localCandidate = candidate || {}
    localCandidate.birthdate = moment(candidate?.birthdate, 'YYYY-MM-DDThh:mm:ss').format('DD/MM/YYYY')
    form.setFieldsValue(localCandidate)
  }, [candidate, form])

  return (
    <div>
      <Form
        layout='horizontal'
        form={form}
        name='register'
        labelAlign='left'
        scrollToFirstError
        {...formItemLayout}
      >
        <Form.Item
          name='name'
          label='Nombre'
          labelCol={{ sm: { span: 3 } }}
          wrapperCol={{ sm: { span: 21 } }}
        >
          <Input className='disabled-input' readOnly placeholder='Nombre' />
        </Form.Item>

        <Group>
          <Row>
            <Col sm={12}>
              <Form.Item
                name='birthdate'
                label='Fecha Nacimiento'
              >
                <Input readOnly placeholder='Fecha de Nacimiento' />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name='birthplace'
                label='Lugar Nacimiento'
              >
                <Input readOnly placeholder='Lugar de Nacimiento' />
              </Form.Item>
            </Col>
          </Row>
        </Group>

        <Group>
          <Row>
            <Col sm={12}>
              <Form.Item
                name='address'
                label='Domicilio'
              >
                <Input className='disabled-input' readOnly placeholder='Domicilio' />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name='city'
                label='Ciudad'
              >
                <Input readOnly placeholder='Ciudad' />
              </Form.Item>
            </Col>
          </Row>
        </Group>

        <Group>
          <Row>
            <Col sm={12}>
              <Form.Item
                name='phone'
                label='Tel. Personal'
              >
                <Input readOnly placeholder='Telefono Personal' />
              </Form.Item>
            </Col>

            <Col sm={12}>
              <Form.Item
                name='residentialPhone'
                label='Tel. Recados'
              >
                <Input readOnly placeholder='Telefono Recados' />
              </Form.Item>
            </Col>
          </Row>
        </Group>

        <Group>
          <Row>
            <Col sm={12}>
              <Form.Item
                name='imss'
                label='No. IMSS'
              >
                <Input readOnly placeholder='Numero IMSS' />
              </Form.Item>
            </Col>

            <Col sm={12}>
              <Form.Item
                name='curp'
                label='CURP'
              >
                <Input readOnly placeholder='Numero CURP' />
              </Form.Item>
            </Col>
          </Row>
        </Group>

        <Form.Item
          name='family'
          label='Familiares'
          labelCol={{ sm: { span: 3 } }}
          wrapperCol={{ sm: { span: 21 } }}
          initialValue={candidate?.family}
        >
          <TextArea readOnly />
        </Form.Item>
      </Form>
    </div>
  )
}

export default CandidateForm
