import React, {useCallback, useEffect, useState} from 'react'
import {Button, Form, Pagination, Row, Tooltip} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {actionTypes} from "../../store/assignments/assignments.types";
import {useIsLoading} from "../../store/app/loading/loading.hooks";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import CustomSearch from "../../components/CustomSearch/CustomSearch";
import {UndoOutlined} from "@ant-design/icons";
import CustomScrollbars from "../../components/Scrolls/CustomScrollbars";
import {updateTopBarTitle} from "../../store/settings/settings.actions";
import {clearActionResult} from "../../store/app/app.actions";
import {searchAssignment} from "../../store/assignments/assignments.actions";
import {selectAssignments} from "../../store/assignments/assignments.selector";
import AssignmentCell from "../../components/Survey/AssignmentCell";

const Assignments = () => {
  const assignments = useSelector(selectAssignments)
  const data = assignments.items || []
  const dispatch = useDispatch()
  const [searchForm] = Form.useForm()
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 })
  const [loading] = useIsLoading([actionTypes.SEARCH_ASSIGNMENT])

  const refresh = useCallback(({ search }) => {
    dispatch(searchAssignment({
      status: 'ENVIADO',
      search,
      page: 1,
      sortOrder: 'ASC',
      sortProperty: 'sentDate',
      searchProperty: 'name',
      ...pagination
    }))
  }, [dispatch, pagination])

  const init = () => {
    dispatch(updateTopBarTitle('Nuevas Encuestas'))
    return () => dispatch(clearActionResult(actionTypes.SEARCH_ASSIGNMENT))
  }

  const onPageChange = (page, pageSize) => {
    setPagination({ page, pageSize })
    searchForm.getFieldsValue()
  }

  useEffect(init, [])
  return (
    <div>
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <LoadingIndicator loading={loading} />
            <br />
            <div className='gx-module-box-topbar flex-header'>
              <CustomSearch form={searchForm} onChange={refresh} dates={false} last={false} />
              <Row justify='end' style={{ marginLeft: 30, paddingRight: 30 }}>
                <Tooltip title='Actualizar' placement='topLeft'>
                  <Button
                    size='large'
                    className='orange-button'
                    shape='circle' type='primary'
                    icon={<UndoOutlined />}
                    aria-label='add' onClick={refresh}
                  />
                </Tooltip>
              </Row>
            </div>
            <CustomScrollbars className='candidate-scroll'>
              {
                data.length === 0
                  ? (
                    <div className='gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center'>
                      Sin Encuestas
                    </div>
                  )
                  : data.map((assignment) => <AssignmentCell assignment={assignment} key={`${assignment.id}`} />)
              }
            </CustomScrollbars>
            <Row justify='end' className='gx-border-top gx-pr-5 gx-pt-2'>
              <Pagination
                showSizeChanger
                defaultCurrent={1}
                onChange={onPageChange}
                total={assignments.total || 0}
                current={assignments.page || 1}
                pageSize={assignments.pageSize || 10}
                hideOnSinglePage={false}
                showTotal={(total, range) => `${range[0]} a ${range[1]} de ${total}`}
              />
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Assignments
