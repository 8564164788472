import { extract, successState } from '../app/app.actions'
import { actionTypes } from './company.types'

const initialState = {
  companies: { page: 1, items: [], pageSize: 10, total: 0, count: 0 },
  created: {},
  invoices: { page: 1, items: [], pageSize: 10, total: 0, count: 0 },
  current: {},
  answers: { judicial: [], sindicato: [], comments: [], jobs: [], surveys: [] }
}

const CompanyReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case successState(actionTypes.SEARCH_COMPANIES):
      return {
        ...state,
        companies: data
      }
    case successState(actionTypes.UPDATE_COMPANY):
    case successState(actionTypes.GET_COMPANY):
      return {
        ...state,
        current: data
      }
    case successState(actionTypes.ADD_COMPANY):
      return {
        ...state,
        created: data
      }
    case successState(actionTypes.SEARCH_INVOICES):
      return {
        ...state,
        invoices: extract(data, initialState.invoices)
      }
    case successState(actionTypes.GET_ANSWERS):
    case successState(actionTypes.ADD_ANSWER):
    case successState(actionTypes.EDIT_ANSWER):
    case successState(actionTypes.REMOVE_ANSWER):
      return {
        ...state,
        answers: extract(data, initialState.answers)
      }
    case successState(actionTypes.CLEAR_COMPANY):
      return initialState
    default:
      return state || initialState
  }
}

export default CompanyReducer
