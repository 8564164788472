import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, DatePicker, Form, Input, Popover, Radio, Row, Select, Tooltip } from 'antd'
import debounce from 'lodash/debounce'
import { SortAscendingOutlined } from '@ant-design/icons'
import moment from 'moment'
import { NOOP } from '../../util/functions.utils'
import { maxDateToday } from '../../util/forms.utils'

const dateFormat = 'DD/MM/YYYY'
const { Option } = Select

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
  marginRight: '30px'
}

const CustomSearch = ({ onChange = NOOP, sortProperties = [], form, style, last = true, dates = true, search = true }) => {
  const [property, setProperty] = useState(sortProperties[0]?.property)
  const [order, setOrder] = useState('ASC')

  const beforeSendChange = useCallback(({ search, last, from, to }) => {
    if (last) {
      form.setFieldsValue({ from: null, to: null })
      onChange({
        search: form.getFieldValue('search'),
        last,
        from: null,
        to: null,
        sortOrder: order,
        sortProperty: property
      }, 'form')
    } else if (from || to) {
      form.setFieldsValue({ last: null })

      if (from && !form.getFieldValue('to')) {
        form.setFieldsValue({ to: moment() })
      }

      onChange({
        search: form.getFieldValue('search'),
        from: from || form.getFieldValue('from'),
        to: to || form.getFieldValue('to'),
        last: null,
        sortOrder: order,
        sortProperty: property
      }, 'form')
    } else if (search) {
      onChange({
        search,
        last: form.getFieldValue('last'),
        from: form.getFieldValue('from'),
        to: form.getFieldValue('to'),
        sortOrder: order,
        sortProperty: property
      }, 'form')
    } else {
      onChange({
        search: form.getFieldValue('search'),
        last: form.getFieldValue('last'),
        from: form.getFieldValue('from'),
        to: form.getFieldValue('to'),
        sortOrder: order,
        sortProperty: property
      }, 'form')
    }
  }, [form, onChange, order, property])

  const debounced = useCallback(debounce(beforeSendChange, 500), [form])

  useEffect(() => {
    beforeSendChange({})
  }, [beforeSendChange, order, property])

  const getFilters = () => {
    return (
      <Row className='gx-p-2'>
        <Col span={12} className='gx-border-right'>
          <Radio.Group onChange={e => setProperty(e.target.value)} value={property}>
            <Col>
              {
                sortProperties.map((item, index) =>
                  <Radio value={item.property} key={`sort-${index}`} style={radioStyle}>{item.label}</Radio>
                )
              }
            </Col>
          </Radio.Group>

        </Col>
        <Col span={12}>
          <Radio.Group onChange={e => setOrder(e.target.value)} value={order}>
            <Radio value='ASC' style={radioStyle}>Ascendente</Radio>
            <Radio value='DESC' style={radioStyle}>Descendente</Radio>
          </Radio.Group>
        </Col>
      </Row>
    )
  }

  return (
    <Form layout='inline' form={form} onValuesChange={debounced} style={{ display: 'flex', flexGrow: 1, ...style }}>
      {
        search &&
          <Form.Item name='search' style={{ display: 'flex', flexGrow: 1 }}>
            <Input placeholder='Buscar' autoComplete='off' type='search' />
          </Form.Item>
      }

      {
        last &&
          <>
            {
              search &&
                <div style={{ borderLeft: '1px solid grey', height: 35, marginLeft: 15, marginRight: 10 }} />
            }
            <Tooltip title='Mostrar'>
              <Form.Item name='last' initialValue={10}>
                <Select
                  style={{ width: 150 }}
                  placeholder='Ultimos...'
                >
                  <Option value={10}>Ultimos 10</Option>
                  <Option value={20}>Ultimos 20</Option>
                  <Option value={50}>Ultimos 50</Option>
                  <Option value={100}>Ultimos 100</Option>
                  <Option value={-1}>Todos</Option>
                </Select>
              </Form.Item>
            </Tooltip>
          </>
      }

      {
        dates &&
          <>
            <div style={{ borderLeft: '1px solid grey', height: 35, marginLeft: 15, marginRight: 10 }} />
            <Form.Item label='Desde' name='from'>
              <DatePicker
                className='gx-w-100'
                disabledDate={maxDateToday}
                format={dateFormat}
              />
            </Form.Item>
            <Form.Item label='Hasta' name='to'>
              <DatePicker
                className='gx-w-100'
                disabledDate={maxDateToday}
                format={dateFormat}
              />
            </Form.Item>
          </>
      }
      {
        sortProperties.length > 0 &&
          <>
            <div style={{ borderLeft: '1px solid grey', height: 35, marginLeft: 15, marginRight: 15 }} />
            <Tooltip title='Ordenar por'>
              <Popover content={getFilters()} title='Ordenar Por' trigger='click' placement='bottomRight'>
                <Button icon={<SortAscendingOutlined />} />
              </Popover>
            </Tooltip>
          </>
      }
    </Form>
  )
}

export default CustomSearch
