import React, {useEffect} from 'react'
import '../../styles/pages/profile.less'
import {Button, Card, Col, Form, Input, Modal, Row} from 'antd'
import {inputRequired} from '../../util/forms.utils'
import {updateTopBarTitle} from '../../store/settings/settings.actions'
import {useDispatch, useSelector} from 'react-redux'
import {updateCurrentUser} from '../../store/auth/auth.actions'
import {useHasErrors} from '../../store/app/error/error.hooks'
import {actionTypes} from '../../store/auth/auth.types'
import {useIsLoading} from '../../store/app/loading/loading.hooks'
import {selectCurrentUser} from '../../store/auth/auth.selectors'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}

const formItemLayoutPass = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  }
}

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
}

const { success, error } = Modal

const UserProfile = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const user = useSelector(selectCurrentUser)
  const [submitError, submitHasError] = useHasErrors([actionTypes.UPDATE_USER])
  const [submitLoading, submitFinished] = useIsLoading([actionTypes.UPDATE_USER])

  const init = () => {
    dispatch(updateTopBarTitle('Perfil de usuario'))
  }

  const handleSubmit = ({ name, oldPassword, newPassword }) => {
    dispatch(updateCurrentUser({ name, oldPassword, newPassword }))
  }

  useEffect(init, [])

  useEffect(() => {
    if (submitFinished) {
      if (submitHasError) {
        error({
          title: '¡Uh-oh!',
          content: submitError.message || 'Ocurro un error, intentalo de nuevo mas tarde'
        })
      } else {
        success({
          title: '¡Éxito!',
          content: 'Usuario editado correctamente'
        })

        form.resetFields()
      }
    }
  }, [submitFinished, submitHasError, submitError.message, form])

  return (
    <Row className='padding-top'>
      <Col span={18} offset={4}>
        <Card className='gx-card' title='Usuario'>
          {/* <Row> */}
          {/*  <Col span={10} /> */}
          {/*  <Col span={6}> */}
          {/*    <Avatar style={{ backgroundColor: randomHexColorByString("Usuario de prueba"), verticalAlign: 'middle' }} size='large'> */}
          {/*      T */}
          {/*    </Avatar> */}
          {/*  </Col> */}
          {/*  <Col span={8} /> */}
          {/* </Row> */}
          {/* <div style={{ height: '20px' }}></div> */}
          <Form
            labelAlign='left'
            {...formItemLayout}
            form={form}
            onFinish={handleSubmit}
          >
            <Form.Item
              name='name'
              label='Nombre'
              rules={[inputRequired]}
              initialValue={user?.name}
              labelCol={{ sm: { span: 4 } }}
              wrapperCol={{ sm: { span: 20 } }}
            >
              <Input
                placeholder='Nombre'
              />
            </Form.Item>
            <Form.Item {...tailLayout} className='text-center'>
              <Button type='primary' htmlType='submit' loading={submitLoading}>
                Guardar
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Col span={18} offset={4}>
        <Card className='gx-card' title='Contraseña'>
          <Form
            labelAlign='left'
            {...formItemLayoutPass}
            form={form}
            onFinish={handleSubmit}
          >
            <Form.Item
              name='oldPassword'
              label='Contraseña anterior'
              labelCol={{ sm: { span: 8 } }}
              wrapperCol={{ sm: { span: 16 } }}
            >
              <Input.Password
                autoComplete='off'
              />
            </Form.Item>
            <Form.Item
              name='newPassword'
              label='Contraseña nueva'
              labelCol={{ sm: { span: 8 } }}
              wrapperCol={{ sm: { span: 16 } }}
            >
              <Input.Password
                autoComplete='off'
              />
            </Form.Item>
            <Form.Item {...tailLayout} className='text-center'>
              <Button type='primary' htmlType='submit' loading={submitLoading}>
                Cambiar Contraseña
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  )
}

export default UserProfile
