import React, { useEffect } from 'react'
import { Avatar, Modal, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { actionTypes } from '../../store/assignments/assignments.types'
import { useHasErrors } from '../../store/app/error/error.hooks'
import { selectIsAdmin, selectIsInvestigator, selectIsSupervisor } from '../../store/auth/auth.selectors'
import { downloadCandidateReport } from '../../store/candidates/candidates.actions'
import { history } from '../../store'
import Row from 'antd/es/grid/row'
import Col from 'antd/es/grid/col'
import moment from 'moment'
import { DownloadOutlined, UserSwitchOutlined } from '@ant-design/icons'
import { processAssignment } from '../../store/assignments/assignments.actions'
const { confirm, error } = Modal

const getColorByStatus = (status) => {
  switch (status) {
    case 1:
      return '#87D068'
    case 2:
      return '#F3D46E'
    case 3:
      return '#FF5A87'
    case 4:
      return 'grey'
    default:
      return 'orange'
  }
}

const AssignmentCell = ({ assignment, handleModal }) => {
  const dispatch = useDispatch()
  const [, finished] = useIsLoading([actionTypes.PROCESS_ASSIGNMENT])
  const [APIError, hasError] = useHasErrors([actionTypes.PROCESS_ASSIGNMENT])
  const isFinished = !!assignment.finishDate
  const inProcess = !!assignment.investigationDate && !isFinished
  const isNew = assignment.assignedTo == null
  const color = isNew ? '#9297C4' : getColorByStatus(4)
  const isInvestigator = useSelector(selectIsInvestigator)
  const isAdmin = useSelector(selectIsAdmin)
  const isSupervisor = useSelector(selectIsSupervisor)

  const downloadReport = () => dispatch(downloadCandidateReport({ assignmentId: assignment.id }))

  const process = () => {
    if (inProcess || isFinished) {
      history.push(`/assignments/process/${assignment.id}`)
    } else {
      confirm({
        title: 'Confirmar',
        content: `${assignment.name.toUpperCase()} ingresará al proceso de investigación`,
        onOk: () => dispatch(processAssignment({ assignmentId: assignment.id }))
      })
    }
  }

  useEffect(() => {
    if (finished) {
      if (hasError) {
        error({
          title: '¡Uh-oh!',
          content: APIError.message || 'Ocurrió un error, por favor intente más tarde'
        })
      }
    }
  }, [assignment.id, APIError, finished, hasError])

  const date = assignment.finishDate || assignment.investigationDate || assignment.sentDate
  const dateMessage = isFinished ? 'envío de resultados' : inProcess ? 'inicio de investigación' : 'envío'

  return (
    <div className='gx-contact-item'>
      <div className='gx-module-list-icon'>
        <div className='gx-ml-2 gx-d-none gx-d-sm-flex'>
          <Avatar style={{ backgroundColor: color, verticalAlign: 'middle' }} size='large'>
            {assignment.name[0].toUpperCase()}
          </Avatar>
        </div>
      </div>
      <div className='gx-module-list-info gx-contact-list-info'>
        <div className='gx-module-contact-content'>
          <Row>
            <Col span={2}>
              <Tooltip title='Folio'>
                <span className='gx-text-truncate'>#{assignment.id} </span>
              </Tooltip>
            </Col>
            <Col span={4}>
              <Tooltip title={`Fecha de ${dateMessage}`}>
                <span className='gx-text-truncate'>{moment(date).format('DD/MM/yyyy')} </span>
              </Tooltip>
            </Col>
            <Col span={isNew ? 18 : 13}>
              <Tooltip title='Nombre del encuestado'>
                <Row>
                  <Col span={6}>
                    <span className='gx-text-uppercase'>{assignment.name}</span>
                    <div className='gx-text-muted'>
                      <span className='gx-email-block gx-mr-2'>
                        {assignment.position}
                      </span>
                    </div>
                  </Col>
                  <Col span={6}><span className='gx-text' /></Col>
                  <Col span={6}><span className='gx-text'>{assignment.contactPhone}</span></Col>
                </Row>
              </Tooltip>
            </Col>
            {
              !isNew &&
                <Col span={5}>
                  <Tooltip title='Usuario'>
                    <span className='gx-text-muted' style={{ fontSize: 12 }}>{assignment.assignedToName}</span>
                  </Tooltip>
                </Col>
            }
          </Row>
        </div>
        <div className='gx-module-contact-right'>
          <Row align='middle'>
            {
              isInvestigator &&
                <Col span={3}>
                  <Tooltip title={isFinished ? 'Ver Resultados' : 'Procesar'} onClick={process}>
                    <i className={`gx-icon-btn icon ${isFinished ? 'icon-check-circle-o' : 'icon-menu-right'}`} />
                  </Tooltip>
                </Col>
            }
            {
              (isAdmin || isSupervisor) && inProcess &&
                <Col span={3}>
                  <Tooltip title='Reasignar'>
                    <span onClick={() => handleModal(assignment)}>
                      <UserSwitchOutlined />
                    </span>
                  </Tooltip>
                </Col>
            }
            {
              isFinished &&
                <Col span={6}>
                  <Tooltip title='Descargar informe' onClick={downloadReport}>
                    <DownloadOutlined />
                  </Tooltip>
                </Col>
            }

          </Row>
        </div>

      </div>

    </div>
  )
}

export default AssignmentCell
