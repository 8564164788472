export const actionTypes = {
  FINISH_ASSIGNMENT: 'assignment/finish',
  PROCESS_ASSIGNMENT: 'assignment/process',
  REASSIGN_ASSIGNMENT: 'assignment/reassign',
  SEARCH_ASSIGNMENT: 'assignment/search',
  SINGLE_ASSIGNMENT: 'assignment/single/get',
  GET_SURVEY: 'survey/get',
  ASSIGNMENT_ANSWERS: 'survey/answers',
  ASSIGNMENT_REPORT: 'survey/report',
  CLEAR_ASSIGNMENT: 'survey/clear'
}
