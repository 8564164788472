import { combineReducers } from 'redux'
import loading from './app/loading/loading.reducer'
import error from './app/error/error.reducer'
import { connectRouter } from 'connected-react-router'
import settings from './settings/settings.reducer'
import navigation from './navigation/navigation.reducer'
import auth from './auth/auth.reducer.js'
import candidates from './candidates/candidates.reducer'
import companies from './company/company.reducer'
import users from './users/users.reducer'
import assignments from './assignments/assignments.reducer'

export const rootReducer = history => combineReducers({
  router: connectRouter(history),
  users,
  loading,
  error,
  settings,
  navigation,
  auth,
  candidates,
  companies,
  assignments
})
