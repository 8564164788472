import React, {useState} from 'react'
import {Col, Form, Input, Row, Transfer} from 'antd'

const ZoneForm = (props) => {

  const form = props.form
  const [branches] = useState(props.branches)
  const [targetKeys, setTargetKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])

  const onChange = (nextTargetKeys, direction, moveKeys) => {
    console.log('targetKeys:', nextTargetKeys)
    console.log('direction:', direction)
    console.log('moveKeys:', moveKeys)
    setTargetKeys(nextTargetKeys)
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    console.log('sourceSelectedKeys:', sourceSelectedKeys)
    console.log('targetSelectedKeys:', targetSelectedKeys)
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
  };

  return (
    <Form
      form={form}
    >
      <Row>
        <Col span={1} />
        <Col span={22}>
          <Form.Item label='Nombre'>
            <Input placeholder='Nombre' />
          </Form.Item>
        </Col>
        <Col span={1} />
      </Row>
      <Row>
        <Col span={1} />
        <Col span={22}>
          <Form.Item>
            <Transfer
              className='gx-ant-transfer-width'
              dataSource={branches}
              showSearch
              listStyle={{
                width: 250,
                height: 300
              }}
              operations={['Agregar', 'Eliminar']}
              onChange={onChange}
              onSelectChange={onSelectChange}
              targetKeys={targetKeys}
              selectedKeys={selectedKeys}
              render={item => `${item.name}`}
              locale={{ itemUnit: 'Sucursales', itemsUnit: 'Sucursales', searchPlaceholder: 'Buscar...', notFoundContent: 'Sin sucursales' }}
            />
          </Form.Item>
        </Col>
        <Col span={1} />
      </Row>
    </Form>
  )
}

export default ZoneForm
