import { executeAction } from '../app/app.actions'
import { actionTypes } from './navigation.types'

export const toggleCollapsedSideNav = (navCollapsed) => dispatch => {
  const process = () => navCollapsed
  dispatch(executeAction(actionTypes.TOGGLE_COLLAPSED_NAV, process))
}

export const updateWindowWidth = (width) => dispatch => {
  const process = () => width
  dispatch(executeAction(actionTypes.WINDOW_WIDTH, process))
}
