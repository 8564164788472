import React, { useState } from 'react'
import { Avatar, Tooltip } from 'antd'
import { randomHexColorByString } from '../../util/functions.utils'
import Row from 'antd/es/grid/row'
import Col from 'antd/es/grid/col'

const CandidateJobCell = (props) => {
  const [job] = useState(props.job)
  const [modal, setModal] = useState(props.modal)
  const [isProcesed] = useState(props.isProcesed)

  return (
    <div className='gx-contact-item'>
      <div className='gx-module-list-icon'>
        <div className='gx-ml-2 gx-d-none gx-d-sm-flex'>
          <Avatar style={{ backgroundColor: randomHexColorByString(job.company), verticalAlign: 'middle' }} size='large'>
            {job.company[0].toUpperCase()}
          </Avatar>
        </div>
      </div>
      <div className='gx-module-list-info gx-contact-list-info'>
        <div className='gx-module-contact-content'>
          <p className='gx-mb-1'>
            <span className='gx-text-truncate gx-contact-name'> Empresa: {job.company} </span>
          </p>
          <p className='gx-mb-1'>
            <span className='gx-text-truncate gx-job-title'>Puesto: {job.position}</span>
          </p>
          <div className='gx-text-muted'>
            <span className='gx-email gx-d-inline-block gx-mr-2'>
              Desde {job.start}
            </span>
            <span className='gx-phone gx-d-inline-block'>
              Hasta {job.end}
            </span>
            <span className='gx-toolbar-separator'>&nbsp;</span>
            <span className='gx-phone gx-d-inline-block'>(1 Año, 3 meses)</span>
          </div>
        </div>
      </div>
      {
        !isProcesed &&
          <Row>
            <Col span={8} />
            <Col span={8}>
              <Tooltip title='Eliminar'>
                <i className='gx-icon-btn icon icon-trash' onClick={() => { props.handleDelete(job.id) }} />
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip title='Editar'>
                <i onClick={() => { setModal({ ...modal, show: false }) }} className='gx-icon-btn icon icon-edit' />
              </Tooltip>
            </Col>
          </Row>
      }
    </div>
  )
}

export default CandidateJobCell
