import React, {useEffect, useState} from 'react'
import {Col, InputNumber, Row, Slider} from "antd";

const RangeQuestion = ({ question, answers, onChange }) => {
  const[inputValue, setInputValue] = useState(1)

  const init = () => {
    setInputValue(answers[question.id])
  }
  useEffect(init, [])

  const onChangeSlide = (value) => {
    setInputValue(value)
    onChange(question.id, value)
  }

  return (
    <div style={{ padding: '20px 0px', overflowX: 'hidden' }}>
      <label htmlFor='questions'>{question.text}</label>
      <br/>
      <br/>
      <Row>
        <Col span={1}>1</Col>
        <Col span={12}>
          <Slider
            min={1}
            max={question.limit}
            onChange={onChangeSlide}
            value={typeof inputValue === 'number' ? inputValue : 0}
          />
        </Col>
        <Col span={1}>{question.limit}</Col>
        <Col span={4}>
          <InputNumber
            min={1}
            max={question.limit}
            style={{ margin: '0 16px' }}
            value={inputValue}
            onChange={onChangeSlide}
          />
        </Col>
        <Col span={6}></Col>
      </Row>
    </div>
  )
}

export default RangeQuestion
