export const groupBy = (data, property) => {
  return data.reduce((rv, x) => {
    (rv[x[property]] = rv[x[property]] || []).push(x)
    return rv
  }, {})
}

export const uniqBy = (a, key) => {
  const seen = new Set()
  return a.filter(item => {
    const k = key(item)
    return seen.has(k) ? false : seen.add(k)
  })
}

export const shuffle = array => {
  const length = array.length
  for (let i = length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i)
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}
