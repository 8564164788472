import React from 'react'
import { Avatar, Col, Row, Tooltip } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import moment from 'moment'

const InvoiceCell = ({ invoice, index, handleDownload }) => {
  return (
    <div className='gx-contact-item'>
      <div className='gx-module-list-icon'>
        <div className='gx-ml-2 gx-d-none gx-d-sm-flex'>
          <Avatar
            style={{ backgroundColor: 'green', verticalAlign: 'middle' }}
            size='large'
          >
            #{index}
          </Avatar>
        </div>
      </div>
      <div className='gx-module-list-info gx-contact-list-info'>
        <div className='gx-module-contact-content'>
          <Row>
            <Col span={12}>
              <Tooltip title='Desde'>
                <span className='gx-text-truncate'>Del {moment(invoice.fromDate).format('DD/MM/YYYY')} Al {moment(invoice.toDate).format('DD/MM/YYYY')}</span>
              </Tooltip>
            </Col>
            <Col span={6}>
              <Tooltip title='Total'>
                <span className='gx-text-muted'>Investigaciones {invoice.total}</span>
              </Tooltip>
            </Col>
            <Col span={6}>
              <Tooltip title='Total'>
                <span className='gx-text-muted'>Encuestas {invoice.totalSurveys}</span>
              </Tooltip>
            </Col>
          </Row>
        </div>
        <div className='gx-module-contact-right'>
          <Row>
            <Col span={24}>
              <Tooltip title='Descargar' onClick={() => handleDownload(invoice.id)}>
                <DownloadOutlined />
              </Tooltip>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default InvoiceCell
