import React, { useEffect, useState } from 'react'
import { updateTopBarTitle } from '../../store/settings/settings.actions'
import { useDispatch } from 'react-redux'
import { Button, Col, Form, Input, Modal, notification, Pagination, Row } from 'antd'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import BranchCell from '../../components/Branch/BranchCell'
import BranchForm from '../../components/Branch/BranchForm'

const staticData = [
  {
    id: 1,
    name: 'Copacabana',
    address: '5950 12700 Montevideo',
    phone: '9750 7700',
    mail: 'AndyBarelaAmador@gustr.com',
    contact: 'Andy Barela Amador'
  },
  {
    id: 2,
    name: 'Ceibo',
    address: '6546 16400 Cuchilla Alta',
    phone: '9655 0919',
    mail: 'BrutusNajeraValadez@superrito.com',
    contact: 'Brutus Najera Valadez'
  },
  {
    id: 3,
    name: 'Republica',
    address: '5246 97002 Blanquillo',
    phone: '9379 8325',
    mail: 'AlciraTorresMoreno@gustr.com\n',
    contact: 'Alcira Torres Moreno'
  }
]

const confirm = Modal.confirm

const Branches = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [id, setId] = useState(30)
  const [form] = Form.useForm()

  const toggleModal = () => { setShowModal(!showModal) }

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description
    })
  }

  const onFinish = (values: any) => {
    values.id = id
    setId(id + 1)
    setData([...data, values])
    console.log(values)
    openNotificationWithIcon('success', 'usuario ' + values.mail + ' Agregado', '')
    form.resetFields()
  }

  const clickOkModal = () => {
    form.validateFields().then(values => {
      if (form.getFieldsError().filter(({ errors }) => errors.length).length > 0) {
        return
      }
      toggleModal()
      form.submit()
    })
  }

  const deleteBranch = (id) => {
    const newData = [...data]
    const index = newData.map((e) => { return e.id }).indexOf(id)
    if (index >= 0) {
      newData.splice(index, 1)
    }
    setData([...newData])
  }

  const showDeleteConfirm = (id, name) => {
    confirm({
      title: 'Desea eliminar la sucursal ' + name + '?',
      content: '',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk () {
        deleteBranch(id)
        openNotificationWithIcon('info', 'Sucursal ' + name + ' Eliminada', '')
      },
      onCancel () {
        console.log('Cancel')
      }
    })
  }

  const init = () => {
    dispatch(updateTopBarTitle('Sucursales'))
    setData([...staticData])
  }

  useEffect(init, [])

  return (
    <div>
      <div className="gx-main-content">
        <div className="gx-app-module">
          <div className="gx-module-box-content">
            <br />
            <div className="gx-module-box-topbar">
              <Form layout={"inline"}>
                <Form.Item>
                  <Input placeholder='Nombre' />
                </Form.Item>
              </Form>
              <div className='separation-search-bar' style={{ width: '82%' }}>
                <Button className='gx-btn-block ant-btn green-button flex-color-button' style={{ maxWidth: '180px' }} type='primary' aria-label='add' onClick={() => { toggleModal() }}>
                  <i className='icon icon-add gx-mr-2 flex-icon-button-7' />
                  <span>Agregar Sucursal</span>
                </Button>
              </div>
            </div>
            {
              data.length > 0?
                <div>
                  <CustomScrollbars className='zones-scroll'>
                    {data.map((branch, index) => <BranchCell key={branch.id} branch={branch} onDelete={showDeleteConfirm} />)}
                  </CustomScrollbars>
                  <Row>
                    <Col span={10} />
                    <Col>
                      <br />
                      <Pagination defaultCurrent={1} total={50} />
                    </Col>
                    <Col span={8} />
                  </Row>
                </div>:
                <CustomScrollbars className='zones-empty-scroll'>
                  <div className='gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center'>
                    Sin Sucursales
                  </div>
                </CustomScrollbars>
            }
          </div>
        </div>
      </div>
      <Modal
        centered
        width={1024}
        title='Agregar Sucursal'
        visible={showModal}
        className='warning-modal'
        onCancel={toggleModal}
        footer={[
          <Button
            key='Cancelar'
            onClick={toggleModal}
          > Cancelar
          </Button>,
          <Button
            key='Registrar'
            type='primary'
            onClick={() => { clickOkModal() }}
          >
            Registrar
          </Button>
        ]}
      >
        <BranchForm form={form} onFinish={onFinish} />
      </Modal>
    </div>
  )
}

export default Branches
