import React from 'react'
import { Spin } from 'antd'

const LoadingIndicator = ({ loading }) => {
  return (
    loading &&
      <div className='global-loading'>
        <Spin size='large' />
      </div>
  )
}
export default LoadingIndicator
