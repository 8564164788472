import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateTopBarTitle } from '../../store/settings/settings.actions'
import { Button, Form, Modal, Pagination, Row, Tooltip } from 'antd'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import CompanySteps from '../../components/Companies/CompanySteps'
import { searchCompanies, toggleCompany } from '../../store/company/company.actions'
import CustomSearch from '../../components/CustomSearch/CustomSearch'
import { PlusOutlined } from '@ant-design/icons'
import { selectCompanies } from '../../store/company/company.selector'
import CompanyCell from '../../components/Companies/CompanyCell'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { actionTypes } from '../../store/company/company.types'
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";

const { confirm } = Modal

const Companies = () => {
  const companies = useSelector(selectCompanies)
  const data = companies.items || []
  const dispatch = useDispatch()
  const [searchForm] = Form.useForm()
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 })
  const [showModal, setShowModal] = useState(false)
  const [, toggleFinished] = useIsLoading([actionTypes.TOGGLE_COMPANY])
  const [loading] = useIsLoading([actionTypes.DOWNLOAD_INVOICE])
  const toggleModal = () => setShowModal(!showModal)

  const refresh = useCallback(({ active, deleted, last, search, searchProperty, sortOrder, sortProperty } = {}) => {
    dispatch(searchCompanies({
      active,
      deleted,
      last,
      search,
      searchProperty,
      sortOrder,
      sortProperty,
      ...pagination
    }))
  }, [dispatch, pagination])

  const init = () => {
    dispatch(updateTopBarTitle('Empresas'))
    dispatch(searchCompanies({ ...pagination }))
  }
  useEffect(init, [])

  const onPageChange = (page, pageSize) => {
    setPagination({ page, pageSize })
    searchForm.getFieldsValue()
  }

  const hideModal = useCallback(() => {
    setShowModal(false)
    refresh(searchForm.getFieldsValue())
    searchForm.resetFields()
  }, [refresh, searchForm])

  const onToggle = (companyId, active) => {
    const message = active
      ? '¿Está seguro que desea desactivar la empresa? Al hacer esto ya no podrán enviar más candidatos a investigación.'
      : '¿Está seguro que desea activar la empresa? Al hacer esto podrán enviar de nuevo más candidatos a investigación.'
    confirm({
      title: 'Confirmar',
      content: message,
      onOk: () => dispatch(toggleCompany({ companyId, active }))
    })
  }
  useEffect(() => {
    if (toggleFinished) {
      refresh()
    }
  }, [refresh, toggleFinished])

  return (
    <div>
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <br />
            <LoadingIndicator loading={loading} />
            <div className='gx-module-box-topbar flex-header'>
              <CustomSearch form={searchForm} onChange={refresh} dates={false} last={false} />
              <Row justify='end' style={{ marginLeft: 20, paddingRight: 30 }}>
                <Tooltip title='Agregar Empresa' placement='topLeft'>
                  <Button
                    size='large'
                    className='green-button'
                    shape='circle' type='primary'
                    icon={<PlusOutlined />}
                    aria-label='add' onClick={toggleModal}
                  />
                </Tooltip>
              </Row>
            </div>
            <CustomScrollbars className='company-scroll'>
              {
                data.length === 0
                  ? (
                    <div className='gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center'>
                      No se encontraron empresas con los filtros indicados.
                    </div>
                  )
                  : data.map((company) => <CompanyCell company={company} key={`${company.id}`} handleToggle={onToggle} />)
              }
            </CustomScrollbars>
            <Row justify='end' className='gx-border-top gx-pr-5 gx-pt-2'>
              <Pagination
                showSizeChanger
                defaultCurrent={1}
                onChange={onPageChange}
                total={companies.total || 0}
                current={companies.page || 1}
                pageSize={companies.pageSize || 10}
                hideOnSinglePage={false}
                showTotal={(total, range) => `${range[0]} a ${range[1]} de ${total}`}
              />
            </Row>
          </div>
        </div>
      </div>
      <Modal
        centered
        width={1024}
        title='Agregar Empresa'
        visible={showModal}
        className='warning-modal'
        onCancel={toggleModal}
        footer={[]}
      >
        <CompanySteps onFinish={hideModal} />
      </Modal>
    </div>
  )
}

export default Companies
