import React from 'react'
import {Avatar, Modal, Tag, Tooltip} from 'antd'
import Row from 'antd/es/grid/row'
import Col from 'antd/es/grid/col'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {generateInvoice} from "../../store/company/company.actions";
import {useDispatch} from "react-redux";

const { confirm } = Modal
const CompanyCell = ({ company, handleToggle }) => {

  const dispatch = useDispatch()

  const handleGenerateInvoice = (companyId) => {
    confirm({
      title: 'Generar corte',
      content: '¿Está seguro que desea hacer el corte de la empresa al día de hoy y generar la factura? Esta acción no se puede deshacer.',
      onOk: () => dispatch(generateInvoice({ companyId: companyId }))
    })
  }

  return (
    <div className='gx-contact-item'>
      <div className='gx-module-list-icon'>
        <div className='gx-ml-2 gx-d-none gx-d-sm-flex'>
          <Avatar style={{ backgroundColor: company.active ? 'green' : 'orange', verticalAlign: 'middle' }} size='large'>
            {company.name[0].toUpperCase()}
          </Avatar>
        </div>
      </div>
      <div className='gx-module-list-info gx-contact-list-info'>
        <div className='gx-module-contact-content'>
          <Row>
            <Col span={6}>
              <Tooltip title='Nombre de la empresa'>
                <span className='gx-text-truncate gx-text-uppercase'>{company.name} </span>
              </Tooltip>
            </Col>
            <Col span={6}>
              <Tooltip title='Fecha del último corte'>
                <span className='gx-text-muted'>{moment(company.lastCut || company.createdAt).format('DD/MM/YYYY')}</span>
              </Tooltip>
            </Col>
            <Col span={6}>
              <Tooltip title='Investigaciones terminadas desde el último corte'>
                <span className='gx-text-muted'>Investigaciones: {company.finishedCount}</span>
              </Tooltip>
            </Col>
            <Col span={6}>
              <Tooltip title='Encuestas terminadas desde el último corte'>
                <span className='gx-text-muted'>Encuestas: {company.surveyFinishedCount}</span>
              </Tooltip>
            </Col>
          </Row>
        </div>
        <div className='gx-module-contact-right'>
          <Row>
            <Col span={4}>
              <div style={{ paddingTop: '9px' }}>
                <Tooltip title={company.active ? 'Desactivar' : 'Activar'}>
                  <Tag onClick={() => handleToggle(company.id, company.active)} color={company.active ? '#87d068' : ''} style={{ minWidth: '60px', textAlign: 'center' }}>
                    {company.active ? 'Activa' : 'Inactiva'}
                  </Tag>
                </Tooltip>
              </div>
            </Col>
            <Col span={4}>
              <div style={{ paddingTop: '9px' }}>
                {(company?.finishedCount === 0 && company?.surveyFinishedCount === 0) ? <div style={{ width: '100px' }} /> :
                  <Tooltip title='Generar corte'>
                    <Tag onClick={() => handleGenerateInvoice(company.id)} color='#008A5E' style={{ minWidth: '60px', textAlign: 'center' }}>
                      Generar Corte
                    </Tag>
                  </Tooltip>
                }
              </div>
            </Col>
            <Col span={4}>
              <Tooltip title='Editar'>
                <Link to={`/company/${company.id}`}>
                  <i className='gx-icon-btn icon icon-edit' />
                </Link>
              </Tooltip>
            </Col>
          </Row>
        </div>

      </div>
    </div>
  )
}

export default CompanyCell
