import { executeAction } from '../app/app.actions'
import { actionTypes } from './company.types'
import CompanyService from './company.service'
import { groupBy } from '../../util/array.utils'

export const searchCompanies = ({ active, deleted, last, page, pageSize, search, searchProperty, sortOrder, sortProperty }) => dispatch => {
  const process = () => CompanyService.search({ active, deleted, last, page, pageSize, search, searchProperty, sortOrder, sortProperty })
  dispatch(executeAction(actionTypes.SEARCH_COMPANIES, process))
}

export const getCompany = ({ companyId }) => dispatch => {
  const process = () => CompanyService.getCompany({ id: companyId })
  dispatch(executeAction(actionTypes.GET_COMPANY, process))
}

export const addCompany = ({ name, companyEmail, companyAddress, companyPhone, contactName, adminEmail, adminName }) => dispatch => {
  const process = () => CompanyService.create({ name, companyEmail, companyAddress, companyPhone, contactName, adminEmail, adminName })
  dispatch(executeAction(actionTypes.ADD_COMPANY, process))
}

export const updateCompany = ({ companyId, address, contactName, email, name, phone }) => dispatch => {
  const process = () => CompanyService.update({ companyId, address, contactName, email, name, phone })
  dispatch(executeAction(actionTypes.UPDATE_COMPANY, process))
}
export const toggleCompany = ({ companyId, active }) => dispatch => {
  const process = () => {
    if (active) {
      return CompanyService.deactivate({ companyId })
    } else {
      return CompanyService.activate({ companyId })
    }
  }
  dispatch(executeAction(actionTypes.TOGGLE_COMPANY, process))
}

export const searchInvoices = ({ companyId, active, deleted, last, page, pageSize, search, searchProperty, sortOrder, sortProperty, from, to }) => dispatch => {
  const process = () => CompanyService.searchInvoices({ companyId, active, deleted, last, page, pageSize, search, searchProperty, sortOrder, sortProperty, from, to })
  dispatch(executeAction(actionTypes.SEARCH_INVOICES, process))
}

export const generateInvoice = ({ companyId }) => dispatch => {
  const process = async () => {
    const result = await CompanyService.generateInvoice({ companyId })
    const { data: { id } } = result

    await CompanyService.downloadInvoice({ companyId, invoiceId: id })

    return result
  }
  dispatch(executeAction(actionTypes.GENERATE_INVOICES, process))
}

export const downLoadInvoice = ({ companyId, invoiceId }) => dispatch => {
  const process = async () => CompanyService.downloadInvoice({ companyId, invoiceId })
  dispatch(executeAction(actionTypes.DOWNLOAD_INVOICE, process))
}

export const getAutomaticAnswers = () => dispatch => {
  const process = async () => {
    const result = await CompanyService.getAutomaticAnswers()
    return groupBy(result.data, 'category')
  }
  dispatch(executeAction(actionTypes.GET_ANSWERS, process))
}

export const addAutomaticAnswer = ({ text, category }) => dispatch => {
  const process = async () => {
    const result = await CompanyService.addAutomaticAnswer({ text, category })
    return groupBy(result.data, 'category')
  }
  dispatch(executeAction(actionTypes.ADD_ANSWER, process))
}

export const editAutomaticAnswer = ({ answerId, text }) => dispatch => {
  const process = async () => {
    const result = await CompanyService.editAutomaticAnswer({ answerId, text })
    return groupBy(result.data, 'category')
  }
  dispatch(executeAction(actionTypes.EDIT_ANSWER, process))
}

export const removeAutomaticAnswer = ({ answerId }) => dispatch => {
  const process = async () => {
    const result = await CompanyService.removeAutomaticAnswer({ answerId })
    return groupBy(result.data, 'category')
  }
  dispatch(executeAction(actionTypes.REMOVE_ANSWER, process))
}

export const updatePositions = (list) => dispatch => {
  const process = async () => {
    const result = await CompanyService.updatePositions(list)
    dispatch(getAutomaticAnswers())
    return groupBy(result.data, 'category')
  }
  dispatch(executeAction(actionTypes.UPDATE_POSITIONS_ANSWERS, process))
}

export const clearCompanies = () => dispatch => {
  dispatch(executeAction(actionTypes.CLEAR_COMPANY))
}
