import moment from 'moment'

export const inputRequired = {
  required: true,
  message: 'Requerido'
}

export const typeEmail = {
  type: 'email',
  message: 'Ingresa un correo electrónico válido'
}

export const min6 = {
  min: 6,
  message: 'La contraseña debe tener al menos 6 caractéres'
}

export const typeNumber = {
  type: 'number',
  message: 'Ingresa un precio válido'
}

export const maxDateToday = (current) => {
  return current && current > moment().endOf('day')
}
