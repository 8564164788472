import {$http, downloadFile} from '../../util/http.utils'

class AssignmentsService {
  search = ({ page, pageSize, sortOrder, sortProperty, last, from, to, search, searchProperty, status, pending = true, investigating, result = 0, byUser }) =>
    $http.get('/admin/assignments', { params: { page, pageSize, sortOrder, sortProperty, last, from, to, search, searchProperty, status, pending, investigating, result, byUser } })

  byId = ({ assignmentId }) =>
    $http.get(`/admin/assignments/${assignmentId}`)

  processAssignment = ({ assignmentId }) =>
    $http.post(`/admin/assignments/${assignmentId}`)

  reassignAssignment =
    ({ assignmentId, userId }) =>
      $http.post(`/assignments/${assignmentId}/reasign`, { userId })

  finish = ({ assignmentId, bulkAnswerRequest }) =>
    $http.post(`/admin/assignments/${assignmentId}/finish`, bulkAnswerRequest)

  answer = ({ assignmentId, bulkAnswerRequest }) =>
    $http.post(`/admin/assignments/${assignmentId}/answer`, bulkAnswerRequest)

  getSurvey = ({assignmentId}) =>
    $http.get(`/admin/assignments/${assignmentId}/survey`)

  getAnswers = ({assignmentId}) =>
    $http.get(`/admin/assignments/${assignmentId}/answers`)

  getReport = ({ assignmentId }) =>
    downloadFile(`/admin/assignments/${assignmentId}/report`)
}
export default new AssignmentsService()
