import React, { useCallback, useEffect, useState } from 'react'
import { Button, Checkbox, Form, Modal, Tabs, Steps } from 'antd'
import SurveyHeader from '../../components/Survey/SurveyHeader'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Widget from '../../components/Widget'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { actionTypes } from '../../store/assignments/assignments.types'
import { selectAssignment, selectSurvey } from '../../store/assignments/assignments.selector'
import { history } from '../../store'
import {
  answerAssignment,
  clearAssignments, finishAssignment,
  getSurvey,
  singleAssignment
} from '../../store/assignments/assignments.actions'
import './ProcessAssignment.less'
import { useHasErrors } from '../../store/app/error/error.hooks'
import { UNEXPECTED_OOPS } from '../../util/messages.utils'
import TextAreaForm from '../../components/Verification/TextAreaForm'
import { selectSurveyCommentsAnswers } from '../../store/company/company.selector'
import { getAutomaticAnswers } from '../../store/company/company.actions'
import TextQuestion from '../../components/Survey/TextQuestion'
import RadioQuestion from '../../components/Survey/RadioQuestion'
import CheckBoxQuestion from '../../components/Survey/CheckBoxQuestion'
import RangeQuestion from '../../components/Survey/RangeQuestion'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
const { Step } = Steps

const { success, confirm, error } = Modal
const { TabPane } = Tabs

const ProcessAssignment = () => {
  const dispatch = useDispatch()
  const { assignmentId } = useParams()
  const assignment = useSelector(selectAssignment)
  const defaultNoAnswers = useSelector(selectSurveyCommentsAnswers)
  const [, processFinished] = useIsLoading([actionTypes.FINISH_ASSIGNMENT])
  const [assignError, assignHasError] = useHasErrors([actionTypes.FINISH_ASSIGNMENT])
  const [, surveyLoading] = useIsLoading([actionTypes.GET_SURVEY])
  const survey = useSelector(selectSurvey)
  const [steps, setSteps] = useState([])
  const [answers, setAnswers] = useState({})
  const [showModal, setShowModal] = useState(false)
  const toggleModal = () => setShowModal(!showModal)
  const [noAnswerComment, setNoAnswerComment] = useState('')
  const [, forceRender] = useState()

  const [current, setCurrent] = useState(0)

  const onSingleValueChange = useCallback((questionId, value) => {
    const ans = answers
    ans[questionId] = value
    setAnswers({ ...ans })
  }, [answers])

  const init = () => {
    dispatch(getSurvey({ assignmentId }))
    dispatch(singleAssignment({ assignmentId }))
    dispatch(getAutomaticAnswers())
    return dispatch(clearAssignments())
  }
  useEffect(init, [])

  useEffect(() => {
    if (processFinished) {
      if (assignHasError) {
        error({
          title: '¡Uh-oh!',
          content: assignError.message || UNEXPECTED_OOPS
        })
      } else {
        success({
          title: '¡Exito!',
          content: 'Encuesta registrada con exito',
          onOk: () => history.push('/assignments/process')
        })
      }
    }
  }, [assignError.message, assignHasError, processFinished])

  useEffect(() => {
    if (surveyLoading && survey !== null) {
      const ans = {}
      const steps = []
      survey.questions
        .sort((a, b) => (a.position > b.position) ? 1 : -1)
        .forEach((question) => { ans[question.id] = '' })
      setAnswers(ans)
      survey.questions.forEach((question, index) => {
        if (question.type === 'text') {
          steps.push({ content: <TextQuestion key={question.id} question={question} answers={answers} onChange={onSingleValueChange} steps={steps} index={index} />, step: index, noAnswer: false, questionId: question.id })
        }
        if (question.type === 'radio') {
          steps.push({ content: <RadioQuestion key={question.id} question={question} answers={answers} onChange={onSingleValueChange} />, step: index, noAnswer: false, questionId: question.id })
        }
        if (question.type === 'check-box') {
          steps.push({ content: <CheckBoxQuestion key={question.id} question={question} answers={answers} onChange={onSingleValueChange} />, step: index, noAnswer: false, questionId: question.id })
        }
        if (question.type === 'range') {
          steps.push({ content: <RangeQuestion key={question.id} question={question} answers={answers} onChange={onSingleValueChange} />, step: index, noAnswer: false, questionId: question.id })
        }
      })
      setSteps(steps)
    }
  }, [answers, onSingleValueChange, survey, surveyLoading])

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const setNoAnswerStep = () => {
    const localSteps = steps
    localSteps[current].noAnswer = !localSteps[current].noAnswer
    setSteps(localSteps)
    forceRender({})
  }

  const confirmSubmit = (noAnswer) => {
    confirm({
      title: 'Confirmar',
      content: '¿Está seguro que desea finalizar la encuesta?',
      onOk: () => {
        if (noAnswer) {
          const bulkAnswerRequest = { noAnswerComments: noAnswerComment, answers: [] }
          dispatch(finishAssignment({ assignmentId, bulkAnswerRequest }))
        } else {
          const bulkAnswerRequest = getAnswerRequest()
          dispatch(answerAssignment({ assignmentId, bulkAnswerRequest }))
        }
      }
    })
  }

  const getAnswerRequest = () => {
    const ans = []
    survey.questions.forEach(question => {
      const step = steps.filter(step => step.questionId === question.id)
      if (step && step.length > 0 && step[0].noAnswer) {
        ans.push({ questionId: question.id, optionId: '0', value: '', noAnswer: true })
      } else {
        if (question.type === 'check-box') {
          answers[question.id].forEach(value => {
            ans.push({ questionId: question.id, optionId: '' + value, value: value, noAnswer: false })
          })
        } else {
          ans.push({ questionId: question.id, optionId: (question.type === 'radio') ? answers[question.id] : '0', value: answers[question.id], noAnswer: false })
        }
      }
    })
    return { noAnswerComments: '', answers: ans }
  }

  // steps[current] && console.log(!noAnswer &&  !((answers[steps[current].questionId].length > 0) &&  answers.hasOwnProperty(steps[current].questionId)))
  // steps[current] && console.log(!steps[current].noAnswer)
  // steps[current] && console.log(!(steps[current].questionId in answers && answers[steps[current].questionId].length > 0))
  // steps[current] && console.log(steps[current])
  return (
    <div>
      <SurveyHeader assignment={assignment} onClick={toggleModal} />
      <div className='gx-profile-content'>
        <Widget title='Preguntas' styleName='gx-card-tabs gx-card-tabs-right gx-card-profile'>
          {survey?.questions && steps.length > 0 &&
            <Tabs defaultActiveKey='1'>
              <TabPane tab='Encuesta' key='1'>
                <CustomScrollbars className='survey-scroll'>
                  <br />
                  <Checkbox checked={steps[current].noAnswer} onChange={setNoAnswerStep}>No desea contestar la pregunta</Checkbox>
                  <br />
                  <br />
                  <Steps current={current}>
                    {steps.map((item, index) => (
                      <Step key={index} />
                    ))}
                  </Steps>
                  <div className='steps-content survey-content-scroll'>{steps[current].content}</div>
                  <div className='steps-action' style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    {current < steps.length - 1 && (
                      <Button
                        type='primary'
                        disabled={!steps[current].noAnswer && !(steps[current].questionId in answers && (answers[steps[current].questionId].length > 0 || answers[steps[current].questionId] > 0))}
                        onClick={() => next()}
                      >
                        Siguiente
                      </Button>
                    )}
                    {current === steps.length - 1 && (
                      <Button
                        disabled={!steps[current].noAnswer && !(steps[current].questionId in answers && (answers[steps[current].questionId].length > 0 || answers[steps[current].questionId] > 0))}
                        type='primary'
                        onClick={() => confirmSubmit(false)}
                      >
                        Finalizar
                      </Button>
                    )}
                    {current > 0 && (
                      <Button
                        style={{ margin: '0 8px' }}
                        onClick={() => prev()}
                      >
                        Anterior
                      </Button>
                    )}
                  </div>
                </CustomScrollbars>
              </TabPane>
            </Tabs>}
        </Widget>
      </div>
      <Modal
        centered
        width={1024}
        title='Registrar sin respuesta'
        visible={showModal}
        className='warning-modal'
        onCancel={toggleModal}
        footer={[
          <Button
            key='Cancelar'
            onClick={toggleModal}
          > Cancelar
          </Button>,
          <Button
            key='Finalizar'
            type='primary'
            htmlType='submit'
            onClick={() => confirmSubmit(true)}
          >
            Registrar
          </Button>
        ]}
      >
        <div>
          <h4>La encuesta se registrara sin ninguna respuesta, ¿Está seguro que desea registrarla?</h4>
          <br />
          <Form
            layout='horizontal'
            labelAlign='left'
          >
            <Form.Item
              name='name'
              label='Agregar Comentario'
              labelCol={{ sm: { span: 4 } }}
              wrapperCol={{ sm: { span: 20 } }}
              value={noAnswerComment}
              onChange={e => setNoAnswerComment(e.target.value)}
            >
              <TextAreaForm
                answers={defaultNoAnswers}
                value={noAnswerComment}
                extraOnchange={setNoAnswerComment}
                noButton
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default ProcessAssignment
