export const containsIgnoreCase = (string, search) => {
  return !string || !search ? false
    : string.toLocaleLowerCase().indexOf(search.trim().toLocaleLowerCase()) !== -1
}

export const sortIgnoreCase = (a, b) => {
  return !a || !b ? 0
    : a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())
}

export const truncate = (str = '', length = 100, ending = '...') => {
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending
  } else {
    return str
  }
}
