import React from 'react'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import { Tabs } from 'antd'
import BranchForm from '../../components/Branch/BranchForm'

const TabPane = Tabs.TabPane

const SingleBranch = (props) => {
  return (
    <div>
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <CustomScrollbars className='candidate-tabs-scroll'>
              <div style={{ padding: '28px' }}>
                <Tabs type='card'>
                  <TabPane tab='Informacion Basica' key='1'>
                    <BranchForm />
                  </TabPane>
                </Tabs>
              </div>
            </CustomScrollbars>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleBranch
