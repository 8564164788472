export const actionTypes = {
  TOGGLE_COLLAPSED_NAV: 'TOGGLE_COLLAPSE_MENU',
  WINDOW_WIDTH: 'WINDOW-WIDTH',
  SWITCH_LANGUAGE: 'SWITCH-LANGUAGE',

  THEME_TYPE: 'THEME_TYPE',
  THEME_COLOR: 'THEME_COLOR',
  NAV_STYLE: 'NAV_STYLE',
  LAYOUT_TYPE: 'LAYOUT_TYPE',

  UPDATE_TITLE: 'settings/title/update'
}
