import React, { useCallback, useEffect, useState } from 'react'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import { Button, Form, Modal, Row, Tooltip } from 'antd'
import { updateTopBarTitle } from '../../store/settings/settings.actions'
import { useDispatch, useSelector } from 'react-redux'
import '../../styles/pages/candidates.less'
import UserCell from '../../components/Users/UserCell'
import UserForm from '../../components/Users/UserForm'
import CustomSearch from '../../components/CustomSearch/CustomSearch'
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'
import {
  createUser,
  deleteUser,
  getUsers,
  resetPassword,
  toggleAdmin, toggleInvestigator,
  toggleSupervision
} from '../../store/users/users.actions'
import { containsIgnoreCase, sortIgnoreCase } from '../../util/text.utils'
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { actionTypes } from '../../store/users/users.types'
import { useHasErrors } from '../../store/app/error/error.hooks'
import { UNEXPECTED_OOPS } from '../../util/messages.utils'
import Text from 'antd/es/typography/Text'
import { selectCurrentUser } from '../../store/auth/auth.selectors'
import { selectUsers, selectPassword } from '../../store/users/users.selector'

const sortProperties = [
  { label: 'Nombre', property: 'name' }
]

const { error, confirm, success } = Modal

const Users = () => {
  const dispatch = useDispatch()
  const [searchForm] = Form.useForm()
  const [form] = Form.useForm()
  const [showModal, setShowModal] = useState(false)
  const [search, setSearch] = useState('')
  const [sortOrder, setSortOrder] = useState('ASC')
  const [loading] = useIsLoading([actionTypes.GET_ADMIN_USERS])
  const [addIsLoading, addFinished] = useIsLoading([actionTypes.ADD_USER])
  const [addError, addHasError] = useHasErrors([actionTypes.ADD_USER])
  const [resetIsLoading, resetFinished] = useIsLoading([actionTypes.RESET_PASSWORD])
  const [resetError, resetHasError] = useHasErrors([actionTypes.RESET_PASSWORD])
  const [, deleteFinished] = useIsLoading([actionTypes.DELETE_USER])
  const [deleteError, deleteHasError] = useHasErrors([actionTypes.DELETE_USER])
  const [formError, setFormError] = useState(null)
  const currentUser = useSelector(selectCurrentUser)
  const password = useSelector(selectPassword)
  const users = useSelector(selectUsers)

  const toggleModal = useCallback(() => setShowModal(!showModal), [showModal])

  const init = () => {
    dispatch(updateTopBarTitle('Usuarios'))
    dispatch(getUsers())
  }
  useEffect(init, [])

  useEffect(() => {
    if (addFinished) {
      toggleModal()
      form.resetFields()
      if (addHasError) {
        error({
          title: '¡Uh-oh!',
          content: addError.message || UNEXPECTED_OOPS
        })
      } else {
        success({
          title: '¡Éxito!',
          content: password
            ? <Text>¡Usuario añadido con éxito! la contraseña es: <Text mark>{password}</Text>, se recomienda cambiarla.</Text>
            : 'El usuario ya existia, se han añadido los permisos.'
        })
      }
    }
  }, [addFinished, addHasError, addError, toggleModal, password, form])

  useEffect(() => {
    if (resetFinished) {
      if (resetHasError) {
        error({
          title: '¡Uh-oh!',
          content: resetError.message || UNEXPECTED_OOPS
        })
      } else {
        success({
          title: '¡Éxito!',
          content: <Text>La contraseña es: <Text mark>{password}</Text></Text>
        })
      }
    }
  }, [resetIsLoading, resetHasError, password, resetFinished, resetError])

  useEffect(() => {
    if (deleteFinished) {
      if (deleteHasError) {
        error({
          title: '¡Uh-oh!',
          content: deleteError.message || UNEXPECTED_OOPS
        })
      } else {
        success({
          title: '¡Éxito!',
          content: 'Usuario eliminado exitosamente'
        })
      }
    }
  }, [deleteFinished, deleteHasError, deleteError])

  const refresh = ({ search, sortOrder }) => {
    setSearch(search)
    setSortOrder(sortOrder)
  }

  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch])

  const handleSetSupervisor = async (user, prev) => {
    if ((!user.investigator && !user.admin) && prev) {
      confirm({
        okType: 'danger',
        okText: 'Eliminar',
        cancelText: 'Cancelar',
        title: '¡Cuidado!',
        icon: <ExclamationCircleOutlined />,
        content: 'Al quitar todos los permisos se eliminará el usuario, ¿Está seguro que desea continuar?',
        onOk: () => dispatch(toggleSupervision({ userId: user.id, prev }))
      })
    } else {
      dispatch(toggleSupervision({ userId: user.id, prev }))
    }
  }

  const handleDeleteUser = (user) => {
    confirm({
      okType: 'danger',
      okText: 'Eliminar',
      cancelText: 'Cancelar',
      title: '¡Cuidado!',
      icon: <ExclamationCircleOutlined />,
      content: `Se eliminará a ${user.name}, esta acción no puede ser revertida.`,
      onOk: () => dispatch(deleteUser({ userId: user.id }))
    })
  }

  const handleResetPassword = (userId) => {
    dispatch(resetPassword({ userId }))
  }

  const handleInvestigator = async (user, prev) => {
    if ((!user.supervisor && !user.admin) && prev) {
      confirm({
        okType: 'danger',
        okText: 'Eliminar',
        cancelText: 'Cancelar',
        title: '¡Cuidado!',
        icon: <ExclamationCircleOutlined />,
        content: 'Al quitar todos los permisos se eliminará el usuario, ¿Está seguro que desea continuar?',
        onOk: () => dispatch(toggleInvestigator({ userId: user.id, prev }))
      })
    } else {
      dispatch(toggleInvestigator({ userId: user.id, prev }))
    }
  }

  const handleAdmin = async (user, prev) => {
    if ((!user.supervisor && !user.investigator) && prev) {
      confirm({
        okType: 'danger',
        okText: 'Eliminar',
        cancelText: 'Cancelar',
        title: '¡Cuidado!',
        icon: <ExclamationCircleOutlined />,
        content: 'Al quitar todos los permisos se eliminará el usuario, ¿Está seguro que desea continuar?',
        onOk: () => dispatch(toggleAdmin({ userId: user.id, prev }))
      })
    } else {
      dispatch(toggleAdmin({ userId: user.id, prev }))
    }
  }

  const handleFormError = (values) => {
    if (values.isInvestigator || values.isSupervisor) {
      setFormError(null)
    }
  }

  const onFinish = async ({ email, name, isInvestigator = false, isSupervisor = false }) => {
    if (!isInvestigator && !isSupervisor) {
      setFormError('El usuario debe tener al menos un rol')
    } else {
      dispatch(createUser({ email, name, isInvestigator, isSupervisor }))
    }
  }

  const filteredUsers = (!search?.trim() ? users : users.filter(u => containsIgnoreCase(u.name, search)))
    .sort((a, b) => sortOrder === 'ASC' ? sortIgnoreCase(a.name, b.name) : sortIgnoreCase(b.name, a.name))

  return (
    <div>
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <LoadingIndicator loading={loading} />
            <br />
            <div className='gx-module-box-topbar'>
              <CustomSearch
                dates={false} last={false} onChange={refresh} form={searchForm}
                sortProperties={sortProperties}
              />
              <Row justify='end' style={{ marginLeft: 30, paddingRight: 30 }}>
                <Tooltip title='Agregar Usuario' placement='topLeft'>
                  <Button
                    size='large'
                    className='green-button'
                    shape='circle' type='primary'
                    icon={<PlusOutlined />}
                    aria-label='add' onClick={toggleModal}
                  />
                </Tooltip>
              </Row>
            </div>
            <CustomScrollbars className='zones-scroll'>
              {
                filteredUsers.length > 0
                  ? (
                    <div>
                      {
                        filteredUsers.map((user) =>
                          <UserCell
                            disabled={user.id === 1}
                            current={currentUser?.id === user.id}
                            user={user} key={`user-${user.id}`}
                            handleSupervisor={handleSetSupervisor}
                            handleResetPassword={handleResetPassword}
                            handleDelete={handleDeleteUser}
                            handleInvestigator={handleInvestigator}
                            handleAdmin={handleAdmin}
                          />
                        )
                      }
                    </div>
                  )
                  : (
                    <div className='gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center'>
                      No se encontraron usuarios con los filtros indicados.
                    </div>
                  )
              }
            </CustomScrollbars>

          </div>
        </div>
      </div>
      <Modal
        centered
        width={700}
        title='Agregar Usuario'
        visible={showModal}
        className='warning-modal'
        onCancel={toggleModal}
        footer={[
          <Button
            key='Cancelar'
            onClick={toggleModal}
          > Cancelar
          </Button>,
          <Button
            key='Registrar'
            type='primary'
            onClick={() => form.submit()}
            loading={addIsLoading}
          >
            Registrar
          </Button>
        ]}
      >
        <UserForm form={form} onFinish={onFinish} onChange={handleFormError} formError={formError} showRoles />
      </Modal>
    </div>
  )
}

export default Users
