import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Col, Form, Pagination, Popover, Row, Select, Tooltip } from 'antd'
import CandidateCell from '../../components/Candidate/CandidateCell'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import '../../styles/pages/candidates.less'
import { useDispatch, useSelector } from 'react-redux'
import { updateTopBarTitle } from '../../store/settings/settings.actions'
import CustomSearch from '../../components/CustomSearch/CustomSearch'
import { selectCandidates } from '../../store/candidates/candidates.selector'
import { searchCandidates } from '../../store/candidates/candidates.actions'
import { selectIsAdmin, selectIsSupervisor } from '../../store/auth/auth.selectors'
import { selectUsers } from '../../store/users/users.selector'
import { UserOutlined } from '@ant-design/icons'
import { clearActionResult } from '../../store/app/app.actions'
import { actionTypes } from '../../store/candidates/candidates.types'
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { searchCompanies } from '../../store/company/company.actions'

const { Option } = Select

const CompletedCandidates = () => {
  const candidates = useSelector(selectCandidates)
  const data = candidates.items || []
  const dispatch = useDispatch()
  const [searchForm] = Form.useForm()
  const [user, setUser] = useState(0)
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 })
  const isAdmin = useSelector(selectIsAdmin)
  const isSupervisor = useSelector(selectIsSupervisor)
  const access = useMemo(() => isAdmin || isSupervisor, [isAdmin, isSupervisor])
  const users = useSelector(selectUsers)
  const [loading] = useIsLoading([actionTypes.DOWNLOAD_REPORT])

  const refresh = useCallback(({ search, last, from, to }) => {
    dispatch(searchCandidates({
      status: 'TERMINADO',
      search,
      sortOrder: 'DESC',
      sortProperty: 'finishDate',
      searchProperty: 'name',
      last,
      from: from?.unix(),
      to: to?.unix(),
      pending: false,
      ...pagination,
      byUser: access ? user : null
    }))
  }, [access, dispatch, pagination, user])

  const init = () => {
    dispatch(updateTopBarTitle('Investigaciones terminadas'))
    dispatch(searchCompanies({ pageSize: 100000 }))
    return () => dispatch(clearActionResult(actionTypes.SEARCH_CANDIDATES))
  }

  const onPageChange = (page, pageSize) => {
    setPagination({ page, pageSize })
    searchForm.getFieldsValue()
  }

  const getUserFilters = () => {
    return (
      <Row className='gx-p-2'>
        <Col>
          <Select style={{ width: 200 }} showSearch optionFilterProp='children' value={user} onChange={setUser}>
            <Option value={0}>Todos</Option>
            {
              users.map((user) =>
                <Option key={`users-${user.id}`} value={user.id}>{user.name}</Option>
              )
            }
          </Select>
        </Col>
      </Row>
    )
  }

  useEffect(init, [])
  return (
    <div>
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <LoadingIndicator loading={loading} />
            <br />
            <div className='gx-module-box-topbar flex-header'>
              <CustomSearch form={searchForm} onChange={refresh} />
              {
                access &&
                  <Row justify='end' style={{ marginLeft: 20, paddingTop: 5, marginRight: 5 }}>
                    <Tooltip title='Filtrar por usuario'>
                      <Popover content={getUserFilters()} title='Filtrar por usuario' trigger='click' placement='bottomRight'>
                        <Button icon={<UserOutlined />} />
                      </Popover>
                    </Tooltip>
                  </Row>
              }
            </div>
            <CustomScrollbars className='process-scroll'>
              {
                data.length === 0
                  ? (
                    <div className='gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center'>
                      Sin Candidatos
                    </div>
                  )
                  : data.map((candidate) => <CandidateCell candidate={candidate} key={`${candidate.id}`} />)
              }
            </CustomScrollbars>
            <Row justify='end' className='gx-border-top gx-pr-5 gx-pt-2'>
              <Pagination
                showSizeChanger
                defaultCurrent={1}
                onChange={onPageChange}
                total={candidates.total || 0}
                current={candidates.page || 1}
                pageSize={candidates.pageSize || 10}
                hideOnSinglePage={false}
                showTotal={(total, range) => `${range[0]} a ${range[1]} de ${total}`}
              />
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CompletedCandidates
