import React, { useEffect, useState } from 'react'
import { Button, Col, Input, Modal, Row, Tag, Tooltip } from 'antd'
import { useDispatch } from 'react-redux'
import {editAutomaticAnswer, removeAutomaticAnswer, updatePositions} from '../../store/company/company.actions'

const { TextArea } = Input
const { confirm } = Modal

const TextAreaForm = ({ value, maxLength = 1000, noButton, onSave, rows = 6, answers = [], editable = false, extraOnchange }) => {
  const [text, setText] = useState()
  const [editing, setEditing] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentId, setCurentId] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    setText(value)
  }, [value])

  const onChange = (e) => {
    setText(e.target.value)
    if(extraOnchange)
      extraOnchange(e.target.value)
  }

  const onEdit = (id, index, text) => {
    setText(text)
    setCurentId(id)
    setEditing(true)
    setCurrentIndex(index + 1)
  }

  const onSubmit = () => {
    if (!editing) {
      onSave(text)
    } else {
      confirm({
        title: 'Confirmar',
        content: `¿Está seguro que desea editar la respuesta predefinida #${currentIndex}?`,
        onOk: () => dispatch(editAutomaticAnswer({ answerId: currentId, text }))
      })
    }
  }

  const onDelete = (answerId, index) => {
    confirm({
      title: 'Confirmar',
      content: `¿Está seguro que desea borrar la respuesta predefinida #${index + 1}?`,
      onOk: () => dispatch(removeAutomaticAnswer({ answerId }))
    })
  }

  const changePosition = (answerId, index, val) => {
    const nowAnswers = answers
    nowAnswers.map((a, i) => a.position = i)
    const answerIndex = nowAnswers.findIndex(a => a.id === answerId)
    if(answerIndex < 0)
      return
    if(index + val > (nowAnswers.length - 1) || index + val < 0)
      return
    const pos = nowAnswers.splice(answerIndex, 1)[0]
    nowAnswers.splice(answerIndex + val, 0, pos)
    nowAnswers.map((a, i) => a.position = i)
    const list = []
    nowAnswers.map(a => list.push({ id: a.id, position: a.position }))
    dispatch(updatePositions(list))
  }

  const cancelEdit = () => {
    if (text) {
      setText('')
    }
    setEditing(false)
    setCurrentIndex(null)
    setCurentId(null)
  }
  useEffect(cancelEdit, [answers])

  return (
    <div>
      {
        answers.length > 0 &&
          <Row align='left' justify='left' style={{ marginBottom: 20 }}>
            {
              answers.sort((a, b) => (a.position > b.position) ? 1 : -1).map((answer, index) =>
                <Col span={24} key={`tag-${answer.id}`}>
                  <div className='automatic-answers-wrapper'>
                    <div>
                      #{index + 1}.
                    </div>
                    <Tag className='clickable-status-tag automatic-answer' onClick={() => {setText(answer.text); if(extraOnchange)extraOnchange(answer.text)}}>
                      {answer.text}
                    </Tag>
                    {
                      editable &&
                        <>
                          <Tooltip title='Editar' onClick={() => onEdit(answer.id, index, answer.text)}>
                            <i className='icon icon-btn icon-edit automatic-answer-edit' />
                          </Tooltip>
                          <Tooltip title='Eliminar' onClick={() => onDelete(answer.id, index)}>
                            <i className='icon icon-btn icon-close automatic-answer-delete' />
                          </Tooltip>
                          <Tooltip title='Subir' onClick={() => changePosition(answer.id, index, -1)}>
                            <i className='icon icon-btn icon-menu-up automatic-answer-position' />
                          </Tooltip>
                          <Tooltip title='Bajar' onClick={() => changePosition(answer.id, index, +1)}>
                            <i className='icon icon-btn icon-menu-down automatic-answer-position' />
                          </Tooltip>
                        </>
                    }
                  </div>
                </Col>
              )
            }
          </Row>
      }
      {
        editing &&
          <span style={{ fontWeight: 'bold' }}>Editando # {currentIndex}</span>
      }
      {
        !editing && editable &&
          <span style={{ fontWeight: 'bold' }}>Agregar respuesta predefinida {currentIndex}</span>
      }
      <TextArea rows={rows} maxLength={maxLength} value={text} defaultValue={value} showCount onChange={onChange} />
      {
        !noButton && (
          <Row>
            <Col span={24} className='flex-center'>
              {editing && <Button key='Cancelar' onClick={cancelEdit}>Cancelar</Button>}
              <Button type='primary' key='Guardar' onClick={onSubmit}>Guardar</Button>
            </Col>
          </Row>
        )
      }
    </div>
  )
}

export default TextAreaForm
