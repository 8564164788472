import CandidateService from './candidates.service'
import { executeAction } from '../app/app.actions'
import { actionTypes } from './candidates.types'
import { history } from '../index'

export const searchCandidates = ({ page, pageSize, sortOrder, sortProperty, last, from, to, search, searchProperty, status, pending = true, investigating, result = 0, byUser }) => dispatch => {
  const process = () => CandidateService.search({ page, pageSize, sortOrder, sortProperty, last, from, to, search, searchProperty, status, pending, investigating, result, byUser })
  dispatch(executeAction(actionTypes.SEARCH_CANDIDATES, process))
}

export const singleCandidate = ({ candidateId }) => dispatch => {
  const process = () => CandidateService.byId({ candidateId })
  dispatch(executeAction(actionTypes.SINGLE_CANDIDATE, process))
}

export const reassignCandidate = ({ candidateId, userId }) => dispatch => {
  const process = () => CandidateService.reassignCandidate({ candidateId, userId })
  dispatch(executeAction(actionTypes.REASSIGN_CANDIDATE, process))
}

export const processCandidate = ({ candidateId }) => dispatch => {
  const process = async () => {
    const result = await CandidateService.processCandidate({ candidateId })
    history.push(`/in-process/${candidateId}`)

    return result
  }
  dispatch(executeAction(actionTypes.PROCESS_CANDIDATE, process))
}

export const getCandidateJobs = ({ candidateId }) => dispatch => {
  const process = () => CandidateService.getJobs({ candidateId })
  dispatch(executeAction(actionTypes.GET_JOBS, process))
}

export const confirmJob = ({ candidateId, jobId }) => dispatch => {
  const process = () => CandidateService.confirmJob({ candidateId, jobId })
  dispatch(executeAction(actionTypes.CONFIRM_JOB, process))
}

export const differJob = ({ candidateId, jobId, comments }) => dispatch => {
  const process = () => CandidateService.differJob({ candidateId, jobId, comments })
  dispatch(executeAction(actionTypes.DIFFER_JOB, process))
}

export const addCandidateJob = ({ candidateId, company, lada, phone, position, boss, start, end, reason }) => dispatch => {
  const process = () => CandidateService.addJob({ candidateId, company, lada, phone, position, boss, start, end, reason })
  dispatch(executeAction(actionTypes.ADD_JOB, process))
}

export const addJudicial = ({ candidateId, text }) => dispatch => {
  const process = () => CandidateService.addJudicial({ candidateId, text })
  dispatch(executeAction(actionTypes.ADD_JUDICIAL, process))
}

export const addSindicatos = ({ candidateId, text }) => dispatch => {
  const process = () => CandidateService.addSindicatos({ candidateId, text })
  dispatch(executeAction(actionTypes.ADD_SINDICATOS, process))
}

export const addComments = ({ candidateId, text }) => dispatch => {
  const process = () => CandidateService.addComments({ candidateId, text })
  dispatch(executeAction(actionTypes.ADD_COMMENTS, process))
}

export const finishCandidate = ({ candidateId, result }) => dispatch => {
  const process = () => CandidateService.finish({ candidateId, result })
  dispatch(executeAction(actionTypes.FINISH_CANDIDATE, process))
}

export const downloadCandidateReport = ({ candidateId }) => dispatch => {
  const process = () => CandidateService.getReport({ candidateId })
  dispatch(executeAction(actionTypes.DOWNLOAD_REPORT, process))
}

export const clearCandidates = () => dispatch => {
  dispatch(executeAction(actionTypes.CLEAR_CANDIDATES))
}
