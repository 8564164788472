import React, { useCallback, useEffect, useState } from 'react'
import Widget from '../Widget'
import { Button, Col, Modal, Row, Tooltip } from 'antd'
import HourglassOutlined from '@ant-design/icons/lib/icons/HourglassOutlined'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentCandidate } from '../../store/candidates/candidates.selector'
import { confirmJob, differJob } from '../../store/candidates/candidates.actions'
import { actionTypes } from '../../store/candidates/candidates.types'
import TextAreaForm from '../Verification/TextAreaForm'
import { selectJobCommentsAnswers } from '../../store/company/company.selector'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { useHasErrors } from '../../store/app/error/error.hooks'
import { UNEXPECTED_OOPS } from '../../util/messages.utils'

const { error, confirm, success } = Modal

const JobData = ({ job, noButton }) => {
  const candidate = useSelector(selectCurrentCandidate)
  const jobAnswers = useSelector(selectJobCommentsAnswers)
  const dispatch = useDispatch()
  const [modalVisible, setModalVisible] = useState(false)
  const [differs, setDiffers] = useState('')
  const [differTextError, setDifferError] = useState('')
  const [, differFinished] = useIsLoading([actionTypes.DIFFER_JOB])
  const [differError, differHasError] = useHasErrors([actionTypes.DIFFER_JOB])
  const toggleModal = useCallback(() => setModalVisible(!modalVisible), [modalVisible])

  const confirmCandidateJob = () => {
    confirm({
      title: 'Confirmar',
      content: `¿Está seguro que desea confirmar ${job.position} en ${job.company} ?`,
      onOk: () => dispatch(confirmJob({ candidateId: candidate.id, jobId: job.id }))
    })
  }

  const toDiffer = (text) => {
    if (text.length) {
      setDiffers(text)
      dispatch(differJob({ candidateId: candidate.id, jobId: job.id, comments: text }))
    } else {
      setDifferError('Este campo es requerido')
    }
  }

  const getRowByStatus = (status) => {
    switch (status) {
      case 0:
        return (
          <Row>
            <Col span={24}>
              <div style={{ display: 'flex', justifyContent: 'left' }}>
                <Tooltip title='Pendiente'>
                  <div style={{ display: 'flex', justifyContent: 'center', padding: '0px 10px' }}><HourglassOutlined /></div>
                </Tooltip>
                <div><b>Pendiente:</b> Este trabajo no ha sido evaluado.</div>
              </div>
            </Col>
          </Row>
        )
      case 1:
        return (
          <Row>
            <Col span={24}>
              <div style={{ display: 'flex', justifyContent: 'left' }}>
                <Tooltip title='Confirmado'>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <i
                      className='gx-icon-btn icon icon-check-circle-o' style={{ color: 'green' }}
                    />
                  </div>
                </Tooltip>
                <div style={{ marginTop: '0.7rem' }}>
                  <b>Confirmado:</b> Este trabajo ya ha sido validado.
                </div>
              </div>
            </Col>
          </Row>
        )
      case 2:
        return (
          <Row>
            <Col span={24}>
              <div style={{ display: 'flex', justifyContent: 'left' }}>
                <Tooltip title='Difiere'>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <i
                      className='gx-icon-btn icon icon-spam'
                      style={{ color: '#F1D065' }}
                    />
                  </div>
                </Tooltip>
                <div style={{ marginTop: '0.7rem' }}>
                  <b>Difiere:</b> {job.comments || 'La informacion de este trabajo difiere respecto a la informacion evaluada.'}
                </div>
              </div>
            </Col>
          </Row>
        )
      case 3:
        return (
          <Row>
            <Col span={24}>
              <div style={{ display: 'flex', justifyContent: 'left' }}>
                <Tooltip title='Omitido'>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <i
                      className='gx-icon-btn icon icon-close-circle' style={{ color: 'red' }}
                    />
                  </div>
                </Tooltip>
                <div style={{ marginTop: '0.7rem' }}>
                  <b>Omitido:</b> Este trabajo fue omitido en la informacion evaluada.
                </div>
              </div>
            </Col>
          </Row>
        )
      default:
        return <div />
    }
  }

  useEffect(() => {
    if (differFinished) {
      toggleModal()
      if (differHasError) {
        error({
          title: '¡Uh-oh!',
          content: differError.message || UNEXPECTED_OOPS
        })
      } else {
        success({
          title: '¡Éxito!',
          content: 'Empleo diferido correctamente'
        })
      }
      setDiffers('')
    }
  }, [differError.message, differFinished, differHasError, toggleModal])

  return (
    <>
      <div className='gx-profile-content' style={{ flex: 1}}>
        <Widget styleName='gx-card-tabs gx-card-tabs-right gx-card-profile' title={job.company || 'Detalles'}>
          <Row>
            <Col span={9}>
              <div className='gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list'>
                <div className='gx-mr-3'>
                  <i className='icon icon-company gx-fs-xxl gx-text-grey' />
                </div>
                <div className='gx-media-body'>
                  <span className='gx-mb-0 gx-text-grey gx-fs-sm'> <br /></span>
                  <Row>
                    <Col span={8}><b>Lada:</b></Col>
                    <Col span={16}>{job.lada}</Col>
                  </Row>
                  <Row>
                    <Col span={8}><b>Telefono:</b></Col>
                    <Col span={16}>{job.phone}</Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col span={15}>
              <div className='gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list'>
                <div className='gx-mr-3'>
                  <i className='icon icon-profile gx-fs-xxl gx-text-grey' />
                </div>
                <div className='gx-media-body'>
                  <span className='gx-mb-0 gx-text-grey gx-fs-sm'> <br /></span>
                  <Row>
                    <Col span={8}><b>Puesto:</b></Col>
                    <Col span={16}>{job.position}</Col>
                  </Row>
                  <Row>
                    <Col span={8}><b>Jefe:</b></Col>
                    <Col span={16}>{job.boss}</Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <div style={{ height: '20px' }} />
          <Row>
            <Col span={9}>
              <div className='gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list'>
                <div className='gx-mr-3'>
                  <i className='icon icon-calendar gx-fs-xxl gx-text-grey' />
                </div>
                <div className='gx-media-body'>
                  <span className='gx-mb-0 gx-text-grey gx-fs-sm'>Periodo</span>
                  <Row>
                    <Col span={8}><b>Ingreso:</b></Col>
                    <Col span={16}>{job.startDate && moment(job.startDate).format('DD/MM/YYYY')}</Col>
                  </Row>
                  <Row>
                    <Col span={8}><b>Baja:</b></Col>
                    <Col span={16}>{job.endDate && moment(job.endDate).format('DD/MM/YYYY')}</Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col span={15}>
              <div className='gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list'>
                <div className='gx-mr-3'>
                  <i className='icon icon-progress gx-fs-xxl gx-text-grey' />
                </div>
                <div className='gx-media-body'>
                  <span className='gx-mb-0 gx-text-grey gx-fs-sm'>Razón de baja</span>
                  <Row>
                    <Col span={24}>{job.reason}</Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <div style={{ height: '20px' }} />
          {getRowByStatus(job.status)}
          <div style={{ height: '20px' }} />
          {
            job.status === 0 && !noButton &&
              <Row>
                <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button key='Difiere' onClick={toggleModal}> Difiere </Button>
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button type='primary' key='Guardar' onClick={confirmCandidateJob}>Confirmado</Button>
                </Col>
              </Row>
          }
          {
            job.status === 1 && !noButton &&
              <Row>
                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button key='Difiere' onClick={toggleModal}> Difiere </Button>
                </Col>
              </Row>
          }
          {
            job.status === 2 && !noButton &&
              <Row>
                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button key='Difiere' onClick={confirmCandidateJob}> Confirmar </Button>
                </Col>
              </Row>
          }
        </Widget>
      </div>
      <Modal
        title={`Diferir ${job.position} en ${job.company}`}
        visible={modalVisible}
        onCancel={toggleModal}
        footer={[]}
      >
        <TextAreaForm
          answers={jobAnswers}
          value={differs}
          textError={differTextError}
          onSave={toDiffer}
        />
      </Modal>
    </>
  )
}

export default JobData
