import { applyMiddleware, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { rootReducer } from './root.reducer'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createBrowserHistory } from 'history'
import thunkMiddleware from 'redux-thunk'

export const history = createBrowserHistory()
const routeMiddleware = routerMiddleware(history)

const persistConfig = {
  key: 'primary',
  version: 0,
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['loading', 'error']
}

const persistedReducer = persistReducer(persistConfig, rootReducer(history))
const middleWareEnhancer = applyMiddleware(thunkMiddleware, routeMiddleware)

const rootStore = createStore(
  persistedReducer,
  composeWithDevTools(middleWareEnhancer)
)

export const store = rootStore
export const persistor = persistStore(rootStore)
