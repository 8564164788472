import React from 'react'
import { Layout, Row, Typography } from 'antd'
import { selectTopBarTitle } from '../../store/settings/settings.selectors'
import { useSelector } from 'react-redux'

const { Header } = Layout
const { Title } = Typography

const Topbar = () => {
  const title = useSelector(selectTopBarTitle)

  return (
    <Header>
      <div>
        <Row>
          <Title level={2} style={{ marginBottom: 0, textTransform: 'uppercase' }}>{title}</Title>
        </Row>
      </div>
    </Header>
  )
}

export default Topbar
