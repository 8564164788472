import { $http } from '../../util/http.utils'

class AuthService {
  login =
    async (email, password) =>
      $http.post('/auth/login/back-office', { email, password })

  updateUser =
    ({ name, newPassword, oldPassword }) =>
      $http.put('/users', { name, newPassword, oldPassword })
}

export default new AuthService()
