import UsersService from './users.service'
import { executeAction } from '../app/app.actions'
import { actionTypes } from './users.types'

export const getUsers = () => dispatch => {
  const process = () => UsersService.getUsers()
  dispatch(executeAction(actionTypes.GET_ADMIN_USERS, process))
}

export const createUserWithEmailAndName = ({ email, name }) => dispatch => {
  const process = () => UsersService.createUserWithEmailAndName({ email, name })
  dispatch(executeAction(actionTypes.ADD_USER, process))
}

export const setCurrentUser = ({ user }) => dispatch => {
  dispatch(executeAction(actionTypes.ADD_USER, user))
}

export const toggleAdmin = ({ userId, prev }) => dispatch => {
  const process = async () => {
    if (prev) {
      return await UsersService.unsetUserAdmin({ userId })
    }

    return await UsersService.setUserAdmin({ userId })
  }
  dispatch(executeAction(actionTypes.TOGGLE_ADMIN, process))
}

export const toggleInvestigator = ({ userId, prev }) => dispatch => {
  const process = async () => {
    if (prev) {
      return await UsersService.unsetUserInvestigator({ userId })
    }

    return await UsersService.setUserInvestigator({ userId })
  }
  dispatch(executeAction(actionTypes.TOGGLE_INVESTIGATOR, process))
}

export const toggleSupervision = ({ userId, prev }) => dispatch => {
  const process = async () => {
    if (prev) {
      return await UsersService.unsetUserSupervisor({ userId })
    }

    return await UsersService.setUserSupervisor({ userId })
  }
  dispatch(executeAction(actionTypes.TOGGLE_SUPERVISOR, process))
}

export const deleteUser = ({ userId }) => dispatch => {
  const process = async () => {
    const resultSupervisor = await UsersService.unsetUserSupervisor({ userId })
    const resultInvestigator = await UsersService.unsetUserInvestigator({ userId })
    const resultAdmin = await UsersService.unsetUserAdmin({ userId })

    return resultAdmin || resultInvestigator || resultSupervisor
  }

  dispatch(executeAction(actionTypes.DELETE_USER, process))
}

export const createUser = ({ email, name, isInvestigator, isSupervisor }) => dispatch => {
  const process = async () => {
    const { data: { exists, user } } = await UsersService.checkUserEmail({ email })
    let result
    let userPassword = ''

    if (!exists && !user) {
      const { data: { user, password } } = await UsersService.createUserWithEmailAndName({ email, name })
      result = user
      userPassword = password
    } else {
      result = user
    }

    let users = []
    if (isInvestigator) {
      const { data } = await UsersService.setUserInvestigator({ userId: result?.id })
      users = data
    }

    if (isSupervisor) {
      const { data } = await UsersService.setUserSupervisor({ userId: result?.id })
      users = data
    }

    return { users, password: userPassword }
  }

  dispatch(executeAction(actionTypes.ADD_USER, process))
}

export const resetPassword = ({ userId }) => dispatch => {
  const process = () => UsersService.resetPassword({ userId })
  dispatch(executeAction(actionTypes.RESET_PASSWORD, process))
}

export const clearUsers = () => dispatch => {
  dispatch(executeAction(actionTypes.CLEAR_USERS))
}
