import React from 'react'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import { TeamOutlined } from '@ant-design/icons'
import SidebarLogo from './SidebarLogo'
import UserProfile from './UserProfile'
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from '../../constants/theme.settings'
import { useSelector } from 'react-redux'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import { selectIsAdmin, selectIsSupervisor } from '../../store/auth/auth.selectors'

const iconStyles = {
  style: {
    fontSize: '20px'
  }
}

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings)
  const { pathname } = useSelector(({ navigation }) => navigation)
  const isSupervisor = useSelector(selectIsSupervisor)
  const isAdmin = useSelector(selectIsAdmin)

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return 'gx-no-header-notifications'
    }
    return ''
  }

  const selectedKeys = pathname.substr(1) || 'home'
  const defaultOpenKeys = selectedKeys.split('/')[1]
  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      <div className='gx-sidebar-content'>
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile />
        </div>
        <CustomScrollbars className='gx-layout-sider-scrollbar'>
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode='inline'
          >
            {/* <Menu.Item key='home'> */}
            {/*  <Link to='/'> */}
            {/*    <HomeOutlined {...iconStyles} /> */}
            {/*    <span>Inicio</span> */}
            {/*  </Link> */}
            {/* </Menu.Item> */}

            <Menu.ItemGroup className='gx-menu-group' title='Investigaciones'>

              <Menu.Item key='new'>
                <Link to='/new'>
                  <i className='icon icon-signup' />
                  <span>Nuevas</span>
                </Link>
              </Menu.Item>

              <Menu.Item key='progress'>
                <Link to='/in-process'>
                  <i className='icon icon-auth-screen' />
                  <span>En Proceso</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>

            <Menu.Item key='finished'>
              <Link to='/finished'>
                <i className='icon icon-check-circle-o' />
                <span>Terminadas</span>
              </Link>
            </Menu.Item>

            <Menu.ItemGroup className='gx-menu-group' title='Encuestas'>
              <Menu.Item key='newAssignments'>
                <Link to='/assignments'>
                  <i className='icon icon-apps-new' />
                  <span>Nuevas</span>
                </Link>
              </Menu.Item>

              <Menu.Item key='processAssignments'>
                <Link to='/assignments/process'>
                  <i className='icon icon-wysiwyg' />
                  <span>En proceso</span>
                </Link>
              </Menu.Item>

              <Menu.Item key='endedAssignments'>
                <Link to='/assignments/completed'>
                  <i className='icon icon-check-square-o' />
                  <span>Finalizadas</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>

            {
              (isSupervisor || isAdmin) &&
                <Menu.ItemGroup className='gx-menu-group' title='Administración'>
                  <Menu.Item key='users'>
                    <Link to='/users'>
                      <TeamOutlined {...iconStyles} />
                      <span>Usuarios</span>
                    </Link>
                  </Menu.Item>
                  {
                    isAdmin &&
                      <Menu.Item key='companies'>
                        <Link to='/companies'>
                          <i className='icon icon-company' />
                          <span>Empresas</span>
                        </Link>
                      </Menu.Item>
                  }
                  {
                    isAdmin &&
                      <Menu.Item key='config'>
                        <Link to='/config'>
                          <i className='icon icon-components' />
                          <span>Configuración</span>
                        </Link>
                      </Menu.Item>
                  }

                </Menu.ItemGroup>
            }

            <Menu.Item key='clients' style={{ marginTop: 'calc(100vh - 635px)' }}>
              <a href='https://clientes.rhglobal.com.mx' target='_blank norefferer'>
                <span>Ir a aplicación de clientes</span>
              </a>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  )
}

SidebarContent.propTypes = {}
export default SidebarContent
