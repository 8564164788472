import { actionTypes } from './settings.types'
import { LAYOUT_TYPE_FULL, NAV_STYLE_FIXED, THEME_TYPE_SEMI_DARK } from '../../constants/theme.settings'
import { successState } from '../app/app.actions'

const initialState = {
  navStyle: NAV_STYLE_FIXED,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_SEMI_DARK,
  themeColor: actionTypes.THEME_COLOR,

  isDirectionRTL: false,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },
  topBarTitle: ''
}

const settings = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.THEME_TYPE):
      return {
        ...state,
        themeType: action.data
      }
    case successState(actionTypes.THEME_COLOR):
      return {
        ...state,
        themeColor: action.data
      }

    case successState(actionTypes.NAV_STYLE):
      return {
        ...state,
        navStyle: action.data
      }
    case successState(actionTypes.LAYOUT_TYPE):
      return {
        ...state,
        layoutType: action.data
      }
    case successState(actionTypes.SWITCH_LANGUAGE):
      return {
        ...state,
        locale: action.data

      }
    case successState(actionTypes.UPDATE_TITLE):
      return {
        ...state,
        topBarTitle: action.data
      }
    default:
      return state
  }
}

export default settings
