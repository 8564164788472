import React, {useState} from 'react'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import {Button, Col, Modal, Row, Table, Tabs} from 'antd'
import ZoneForm from '../../components/Zone/ZoneForm'
import UserForm from "../../components/Users/UserForm";

const staticBranches = [
  {
    key: '1',
    name: 'Copacabana',
    address: '5950 12700 Montevideo',
    phone: '9750 7700',
    mail: 'AndyBarelaAmador@gustr.com',
    contact: 'Andy Barela Amador'
  },
  {
    key: '2',
    name: 'Ceibo',
    address: '6546 16400 Cuchilla Alta',
    phone: '9655 0919',
    mail: 'BrutusNajeraValadez@superrito.com',
    contact: 'Brutus Najera Valadez'
  },
  {
    key: '3',
    name: 'Republica',
    address: '5246 97002 Blanquillo',
    phone: '9379 8325',
    mail: 'AlciraTorresMoreno@gustr.com\n',
    contact: 'Alcira Torres Moreno'
  }
]

const columns = [{
  title: 'Nombre',
  dataIndex: 'name',
  width: 150,
}, {
  title: 'Correo',
  dataIndex: 'mail',
  width: 150,
}, {
  title: 'Acciones',
  dataIndex: 'index'
}];

const data = [{
  key: '1',
  name: 'John Brown',
  mail: 'jbrown@mail.es',
  address: 'New York Park'
}, {
  key: '2',
  name: 'Jim Green',
  mail: 'jgreen@mail.es',
  address: 'London Park'
}]

const TabPane = Tabs.TabPane

const SingleZone = () => {

  const [showModal, setShowModal] = useState(false)

  const toggleModal = () => { setShowModal(!showModal) }

  return (
    <div>
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <CustomScrollbars className='single-zone-tabs-scroll'>
              <div style={{ padding: '28px' }}>
                <Tabs type='card'>
                  <TabPane tab='Informacion Basica' key='1'>
                    <div>
                      <ZoneForm branches={staticBranches} />
                    </div>
                  </TabPane>
                  <TabPane tab='Supervisores' key='2'>
                    <div>
                      <Row>
                        <Col span={1} />
                        <Col span={22}>
                          <Table className="gx-table-responsive" columns={columns} dataSource={data} pagination={{pageSize: 50}} scroll={{y: 240}} />
                        </Col>
                        <Col span={1} />
                      </Row>
                      <Row>
                        <Col span={18} />
                        <Col span={5}>
                          <Button className='gx-btn-block ant-btn green-button flex-color-button' type='primary' onClick={() => { toggleModal() }} aria-label='add'>
                            <i className='icon icon-add gx-mr-2 flex-icon-button-7' />
                            <span>Agregar Supervisor</span>
                          </Button>
                        </Col>
                        <Col span={1} />
                      </Row>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </CustomScrollbars>
          </div>
        </div>
      </div>
      <Modal
        centered
        width={700}
        title='Agregar Usuario'
        visible={showModal}
        className='warning-modal'
        onCancel={toggleModal}
        footer={[
          <Button
            key='Cancelar'
            onClick={toggleModal}
          > Cancelar
          </Button>,
          <Button
            key='Registrar'
            type='primary'
            onClick={() => { toggleModal() }}
          >
            Registrar
          </Button>
        ]}
      >
        <UserForm showRoles={false} />
      </Modal>
    </div>
  )
}

export default SingleZone
