import { actionTypes } from './navigation.types'
import { successState } from '../app/app.actions'

const initialState = {
  navCollapsed: true,
  width: window.innerWidth,
  pathname: '/'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      return {
        ...state,
        pathname: action.payload.location.pathname,
        navCollapsed: false
      }
    }
    case successState(actionTypes.WINDOW_WIDTH):
      return {
        ...state,
        width: action.width
      }
    case successState(actionTypes.TOGGLE_COLLAPSED_NAV): {
      return {
        ...state,
        navCollapsed: action.data
      }
    }
    default:
      return state || initialState
  }
}
