import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Table, Tooltip } from 'antd'
import { HourglassOutlined } from '@ant-design/icons'
import JobForm from './JobForm'
import { useDispatch, useSelector } from 'react-redux'
import { selectCandidateJobs, selectCurrentCandidate } from '../../store/candidates/candidates.selector'
import moment from 'moment'
import { addCandidateJob } from '../../store/candidates/candidates.actions'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { actionTypes } from '../../store/candidates/candidates.types'
import Widget from '../Widget'

const columns = [{
  title: 'Estado',
  dataIndex: 'status',
  width: 10,
  height: 20,
  render: status => {
    switch (status) {
      case 0:
        return (
          <Tooltip title='Pendiente'>
            <div style={{ display: 'flex', justifyContent: 'center' }}><HourglassOutlined /></div>
          </Tooltip>
        )
      case 1:
        return (
          <Tooltip title='Confirmado'>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <i
                className='gx-icon-btn icon icon-check-circle-o'
                style={{ color: 'green' }}
              />
            </div>
          </Tooltip>
        )
      case 2:
        return (
          <Tooltip title='Difiere'>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <i
                className='gx-icon-btn icon icon-spam'
                style={{ color: '#F1D065' }}
              />
            </div>
          </Tooltip>
        )
      case 3:
        return (
          <Tooltip title='Omitido'>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <i
                className='gx-icon-btn icon icon-close-circle'
                style={{ color: 'red' }}
              />
            </div>
          </Tooltip>
        )
      default:
        return <div />
    }
  }
},
{
  title: 'Empresa',
  dataIndex: 'company',
  width: 90,
  height: 20
}, {
  title: 'Ingreso',
  dataIndex: 'startDate',
  render: start => moment(start).format('DD/MM/YYYY'),
  width: 100,
  height: 20
}, {
  title: 'Baja',
  dataIndex: 'endDate',
  render: end => end ? moment(end).format('DD/MM/YYYY') : '',
  width: 100,
  height: 20
}]

const JobList = ({ onClick, showButton }) => {
  const [showModal, setShowModal] = useState(false)
  const [jobForm] = Form.useForm()
  const dispatch = useDispatch()
  const candidate = useSelector(selectCurrentCandidate)
  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const jobs = useSelector(selectCandidateJobs)
  const [, finished] = useIsLoading([actionTypes.ADD_JOB])
  const onRow = record => ({
    onClick: () => onClick(record)
  })

  useEffect(() => {
    if (finished) {
      setShowModal(false)
    }
  }, [finished])

  const onSave = ({ company, lada, phone, position, boss, start, end, reason }) => {
    dispatch(addCandidateJob({
      candidateId: candidate.id,
      company,
      lada,
      phone,
      position,
      boss,
      start: start.format('DD/MM/YYYY'),
      end: end ? end.format('DD/MM/YYYY') : null,
      reason
    }))
  }
  return (
    <Widget styleName='gx-card-tabs gx-card-tabs-right gx-card-profile' title='Empleos'>
      <Table className='gx-table-responsive' style={{ marginTop: 5 }} pagination={false} columns={columns} dataSource={jobs} size='small' onRow={onRow} />
      <div style={{ height: '10px' }} />
      <Row>
        {
          showButton &&
            <Col span={24} className='flex-center'>
              <Button type='primary' key='Guardar' onClick={toggleModal}>Agregar Omitido</Button>
            </Col>
        }
      </Row>
      <Modal
        centered
        width={700}
        title='Agregar Empleo omitido'
        visible={showModal}
        onCancel={toggleModal}
        footer={[
          <Button
            key='Cancelar'
            onClick={toggleModal}
          > Cancelar
          </Button>,
          <Button
            key='Registrar'
            type='primary'
            onClick={() => jobForm.submit()}
          >
            Registrar
          </Button>
        ]}
      >
        <JobForm form={jobForm} onSave={onSave} />
      </Modal>
    </Widget>
  )
}

export default JobList
