import React, { useEffect } from 'react'
import { Avatar, Modal, Tag, Tooltip } from 'antd'
import Row from 'antd/es/grid/row'
import Col from 'antd/es/grid/col'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { downloadCandidateReport, processCandidate } from '../../store/candidates/candidates.actions'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { actionTypes } from '../../store/candidates/candidates.types'
import { useHasErrors } from '../../store/app/error/error.hooks'
import { history } from '../../store'
import { selectIsAdmin, selectIsInvestigator, selectIsSupervisor } from '../../store/auth/auth.selectors'
import { DownloadOutlined, UserSwitchOutlined } from '@ant-design/icons'
import { downloadAssignmentReport } from '../../store/assignments/assignments.actions'
import { selectCompanies } from '../../store/company/company.selector'

const { confirm, error } = Modal

const getColorByStatus = (status) => {
  switch (status) {
    case 1:
      return '#87D068'
    case 2:
      return '#F3D46E'
    case 3:
      return '#FF5A87'
    default:
      return 'orange'
  }
}

const CandidateCell = ({ candidate, handleModal, isAssignment }) => {
  const dispatch = useDispatch()
  const [, finished] = useIsLoading([actionTypes.PROCESS_CANDIDATE])
  const [APIError, hasError] = useHasErrors([actionTypes.PROCESS_CANDIDATE])
  const isFinished = !!candidate.finishDate
  const inProcess = !!candidate.investigationDate && !isFinished
  const isNew = candidate.assignedTo == null
  const color = isNew ? '#1890FF' : getColorByStatus(candidate.result)
  const isInvestigator = useSelector(selectIsInvestigator)
  const isAdmin = useSelector(selectIsAdmin)
  const isSupervisor = useSelector(selectIsSupervisor)
  const companies = useSelector(selectCompanies)

  const downloadReport = () => {
    if (!isAssignment) {
      dispatch(downloadCandidateReport({ candidateId: candidate.id }))
    } else {
      dispatch(downloadAssignmentReport({ assignmentId: candidate.id }))
    }
  }

  const process = () => {
    if (inProcess || isFinished) {
      if (!isAssignment) {
        history.push(`/in-process/${candidate.id}`)
      } else {
        history.push(`/assignments/completed/${candidate.id}`)
      }
    } else {
      confirm({
        title: 'Confirmar',
        content: `${candidate.name.toUpperCase()} ingresará al proceso de investigación`,
        onOk: () => dispatch(processCandidate({ candidateId: candidate.id }))
      })
    }
  }

  useEffect(() => {
    if (finished) {
      if (hasError) {
        error({
          title: '¡Uh-oh!',
          content: APIError.message || 'Ocurrió un error, por favor intente más tarde'
        })
      }
    }
  }, [candidate.id, APIError, finished, hasError])

  const getTagByStatus = (status) => {
    switch (status) {
      case 1:
        return (
          <Tag color='#87D068' className='status-tag'>APTO</Tag>
        )
      case 2:
        return (
          <Tag color='#F3D46E' className='status-tag'>APTO C/R</Tag>
        )
      case 3:
        return (
          <Tag color='#FF5A87' className='status-tag'>NO APTO</Tag>
        )
      default:
        return (
          <Tag color='#FFFFFF' className='status-tag'>SIN INFORMACION</Tag>
        )
    }
  }

  const getTagByAnswer = (assignment) => {
    if (assignment.noAnswer) { return <Tag color='#FF5A87' style={{ fontSize: '15px', minWidth: '150px' }} className='status-tag'>SIN RESPUESTA</Tag> } else { return <Tag color='#87D068' style={{ fontSize: '15px', minWidth: '150px' }} className='status-tag'>COMPLETADO</Tag> }
  }

  const getCompany = (companyId) => {
    return companies?.items?.find(c => c.id === companyId)?.name || ''
  }

  const date = candidate.finishDate || candidate.investigationDate || candidate.sentDate
  const dateMessage = isFinished ? 'envío de resultados' : inProcess ? 'inicio de investigación' : 'envío'
  return (
    <div className='gx-contact-item'>
      <div className='gx-module-list-icon'>
        <div className='gx-ml-2 gx-d-none gx-d-sm-flex'>
          <Avatar style={{ backgroundColor: color, verticalAlign: 'middle' }} size='large'>
            {candidate.name[0].toUpperCase()}
          </Avatar>
        </div>
      </div>
      <div className='gx-module-list-info gx-contact-list-info'>
        <div className='gx-module-contact-content'>
          <Row>
            <Col span={24}>
              <Tooltip title='Empresa que solicita'>
                <span className='gx-text-muted'>{getCompany(candidate.companyId)}</span>
              </Tooltip>
            </Col>
          </Row>
          <Row>
            <Col span={1}>
              <Tooltip title='Folio'>
                #{candidate.id}
              </Tooltip>
            </Col>
            <Col span={4}>
              <Row>
                <Tooltip title={`Fecha de ${dateMessage}`}>
                  <span className='gx-text-truncate'>{moment(date).format('DD/MM/yyyy')} </span>
                </Tooltip>
              </Row>
            </Col>
            {
              !isAssignment &&
                <>
                  <Col span={isNew ? 18 : 13}>
                    <Tooltip title='Nombre del candidato'>
                      <span className='gx-text-uppercase'>{candidate.name}</span>
                    </Tooltip>
                  </Col>
                  {
                    !isNew &&
                      <Col span={5}>
                        <Tooltip title='Usuario'>
                          <span className='gx-text-muted' style={{ fontSize: 12 }}>{candidate.assignedToName}</span>
                        </Tooltip>
                      </Col>
                  }
                </>
            }
            {
              isAssignment &&
                <>
                  <Col span={13}>
                    <Tooltip title='Nombre del candidato'>
                      <span className='gx-text-uppercase'>{candidate.name}</span>
                    </Tooltip>
                  </Col>
                  <Col span={5}>
                    <Tooltip title='Usuario'>
                      <span className='gx-text-muted' style={{ fontSize: 12 }}>{candidate.position}</span>
                    </Tooltip>
                  </Col>
                </>
            }
          </Row>
        </div>
        <div className='gx-module-contact-right'>
          <Row align='middle'>
            {
              isFinished &&
                <Col span={12}>
                  <Tooltip title='Resultado'>
                    {!isAssignment ? getTagByStatus(candidate.result) : getTagByAnswer(candidate)}
                  </Tooltip>
                </Col>
            }
            {
              isInvestigator &&
                <Col span={6}>
                  <Tooltip title={isFinished ? 'Ver Resultados' : 'Procesar'} onClick={process}>
                    <i className={`gx-icon-btn icon ${isFinished ? 'icon-check-circle-o' : 'icon-menu-right'}`} />
                  </Tooltip>
                </Col>
            }{
              (isAdmin || isSupervisor) && inProcess &&
                <Col span={3}>
                  <Tooltip title='Reasignar'>
                    <span onClick={() => handleModal(candidate)}>
                      <UserSwitchOutlined />
                    </span>
                  </Tooltip>
                </Col>
            }
            {
              isFinished &&
                <Col span={6}>
                  <Tooltip title='Descargar informe' onClick={downloadReport}>
                    <DownloadOutlined />
                  </Tooltip>
                </Col>
            }

          </Row>
        </div>

      </div>
    </div>
  )
}

export default CandidateCell
