import { executeAction } from '../app/app.actions'
import { actionTypes } from './auth.types'
import {store} from "../index";
import {clearUsers} from "../users/users.actions";
import {clearCompanies} from "../company/company.actions";
import {clearCandidates} from "../candidates/candidates.actions";
import {clearAssignments} from "../assignments/assignments.actions";

export const showLogout = () => dispatch => {
  const process = () => false
  dispatch(executeAction(actionTypes.SHOW_LOGOUT, process))
}

export const logout = () => dispatch => {
  store.dispatch(clearUsers())
  store.dispatch(clearCompanies())
  store.dispatch(clearCandidates())
  store.dispatch(clearAssignments())
  dispatch(executeAction(actionTypes.LOGOUT))
}
