import React, { useState } from 'react'
import { Avatar, Tooltip } from 'antd'
import { randomHexColorByString } from '../../util/functions.utils'
import Row from 'antd/es/grid/row'
import Col from 'antd/es/grid/col'
import {Link} from "react-router-dom";

const ZoneCell = (props) => {

  const [zone] = useState(props.zone)

  return (
    <div className='gx-contact-item'>
      <div className='gx-module-list-icon'>
        <div className='gx-ml-2 gx-d-none gx-d-sm-flex'>
          <Avatar style={{ backgroundColor: randomHexColorByString(zone.name), verticalAlign: 'middle' }} size='large'>
            {zone.name[0].toUpperCase()}
          </Avatar>
        </div>
      </div>
      <div className='gx-module-list-info gx-contact-list-info'>
        <div className='gx-module-contact-content'>
          <Row>
            <Col span={4}>
              <span className='gx-text-truncate'>{zone.name} </span>
            </Col>
            <Col span={4} />
            <Col span={14}>
              <span className='gx-text-uppercase'>Sucursales: {zone.branches.length}</span>
            </Col>
          </Row>
        </div>
        <div className='gx-module-contact-right'>
          <Row>
            <Col span={8}>
              <Tooltip title='Eliminar'>
                <i className='gx-icon-btn icon icon-trash' onClick={() => { props.onDelete(zone.id, zone.name) }} />
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip title='Editar'>
                <Link to='/zones/zone'><i className='gx-icon-btn icon icon-edit' /></Link>
              </Tooltip>
            </Col>
          </Row>
        </div>

      </div>
    </div>
  )
}

export default ZoneCell
