import React, { useCallback, useEffect, useState } from 'react'
import { Button, Form, Pagination, Row, Tooltip } from 'antd'
import CandidateCell from '../../components/Candidate/CandidateCell'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import '../../styles/pages/candidates.less'
import { useDispatch, useSelector } from 'react-redux'
import { updateTopBarTitle } from '../../store/settings/settings.actions'
import CustomSearch from '../../components/CustomSearch/CustomSearch'
import { selectCandidates } from '../../store/candidates/candidates.selector'
import { searchCandidates } from '../../store/candidates/candidates.actions'
import { clearActionResult } from '../../store/app/app.actions'
import { actionTypes } from '../../store/candidates/candidates.types'
import { UndoOutlined } from '@ant-design/icons'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator'
import { searchCompanies } from '../../store/company/company.actions'

const DraftCandidates = () => {
  const candidates = useSelector(selectCandidates)
  const data = candidates.items || []
  const dispatch = useDispatch()
  const [searchForm] = Form.useForm()
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 })
  const [loading] = useIsLoading([actionTypes.SEARCH_CANDIDATES])

  const refresh = useCallback(({ search }) => {
    dispatch(searchCandidates({
      status: 'ENVIADO',
      search,
      page: 1,
      sortOrder: 'ASC',
      sortProperty: 'sentDate',
      searchProperty: 'name',
      ...pagination
    }))
  }, [dispatch, pagination])

  const init = () => {
    dispatch(updateTopBarTitle('Nuevas Investigaciones'))
    dispatch(searchCompanies({ pageSize: 100000 }))
    return () => dispatch(clearActionResult(actionTypes.SEARCH_CANDIDATES))
  }

  const onPageChange = (page, pageSize) => {
    setPagination({ page, pageSize })
    searchForm.getFieldsValue()
  }

  useEffect(init, [])
  return (
    <div>
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <LoadingIndicator loading={loading} />
            <br />
            <div className='gx-module-box-topbar flex-header'>
              <CustomSearch form={searchForm} onChange={refresh} dates={false} last={false} />
              <Row justify='end' style={{ marginLeft: 30, paddingRight: 30 }}>
                <Tooltip title='Actualizar' placement='topLeft'>
                  <Button
                    size='large'
                    className='orange-button'
                    shape='circle' type='primary'
                    icon={<UndoOutlined />}
                    aria-label='add' onClick={refresh}
                  />
                </Tooltip>
              </Row>
            </div>
            <CustomScrollbars className='candidate-scroll'>
              {
                data.length === 0
                  ? (
                    <div className='gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center'>
                      Sin Candidatos
                    </div>
                  )
                  : data.map((candidate) => <CandidateCell candidate={candidate} key={`${candidate.id}`} />)
              }
            </CustomScrollbars>
            <Row justify='end' className='gx-border-top gx-pr-5 gx-pt-2'>
              <Pagination
                showSizeChanger
                defaultCurrent={1}
                onChange={onPageChange}
                total={candidates.total || 0}
                current={candidates.page || 1}
                pageSize={candidates.pageSize || 10}
                hideOnSinglePage={false}
                showTotal={(total, range) => `${range[0]} a ${range[1]} de ${total}`}
              />
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}
export default DraftCandidates
