import React from 'react'
import {Col, DatePicker, Form, Input, Row} from "antd";
import {inputRequired} from "../../util/forms.utils";
import moment from "moment";

const dateFormat = 'YYYY/MM/DD'

const CandidateJobsForm = (props) => {
  const InputGroup = Input.Group
  const form = props.form

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  }

  return (
    <div>
      <Form
        layout='horizontal'
        form={form}
        name='register'
        scrollToFirstError
        onFinish={props.onFinish}
        {...formItemLayout}
      >
        <Form.Item
          name='company'
          label='Empresa'
          rules={[inputRequired]}
          labelCol={{ sm: { span: 4 } }}
          wrapperCol={{ sm: { span: 20 } }}
        >
          <Input placeholder='Nombre de la empresa' />
        </Form.Item>

        <InputGroup>
          <Row>
            <Col sm={12}>
              <Form.Item
                name='lada'
                label='Lada'
                rules={[inputRequired]}
              >
                <Input placeholder='Lada' />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name='phone'
                label='Telefono'
                rules={[inputRequired]}
              >
                <Input placeholder='Telefono' />
              </Form.Item>
            </Col>
          </Row>
        </InputGroup>

        <Form.Item
          name='position'
          label='Puesto'
          rules={[inputRequired]}
          labelCol={{ sm: { span: 4 } }}
          wrapperCol={{ sm: { span: 20 } }}
        >
          <Input placeholder='Puesto' />
        </Form.Item>

        <Form.Item
          name='employer'
          label='Jefe'
          rules={[inputRequired]}
          labelCol={{ sm: { span: 4 } }}
          wrapperCol={{ sm: { span: 20 } }}
        >
          <Input placeholder='Jefe directo' />
        </Form.Item>

        <InputGroup>
          <Row>
            <Col sm={12}>
              <Form.Item
                name='startObject'
                label='Ingreso'
                rules={[inputRequired]}
              >
                <DatePicker
                  className='gx-w-100' defaultValue={moment(new Date(), dateFormat)}
                  format={dateFormat}
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name='endObject'
                label='Baja'
              >
                <DatePicker
                  className='gx-w-100' defaultValue={moment(new Date(), dateFormat)}
                  format={dateFormat}
                />
              </Form.Item>
            </Col>
          </Row>
        </InputGroup>

        <Form.Item
          name='withdrawal'
          label='Baja'
          rules={[inputRequired]}
          labelCol={{ sm: { span: 4 } }}
          wrapperCol={{ sm: { span: 20 } }}
        >
          <Input placeholder='Motivo de baja' />
        </Form.Item>

      </Form>
    </div>
  )
}

export default CandidateJobsForm
