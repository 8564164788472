export const actionTypes = {
  GET_ADMIN_USERS: 'admin/users/get',
  ADD_USER: 'admin/users/add',
  UPDATE_USERS: 'admin/users/update',
  DELETE_USER: 'admin/users/delete',
  RESET_PASSWORD: 'users/reset/password',
  TOGGLE_INVESTIGATOR: 'admin/users/toggle/investigator',
  TOGGLE_SUPERVISOR: 'admin/users/toggle/supervisor',
  TOGGLE_ADMIN: 'admin/users/toggle/admin',
  CLEAR_USERS: 'admin/clear'
}
