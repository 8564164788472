import { extract, successState } from '../app/app.actions'
import { actionTypes } from './users.types'

const initialState = {
  adminUsers: [],
  password: ''
}

const usersReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case successState(actionTypes.TOGGLE_ADMIN):
    case successState(actionTypes.TOGGLE_SUPERVISOR):
    case successState(actionTypes.TOGGLE_INVESTIGATOR):
    case successState(actionTypes.GET_ADMIN_USERS):
    case successState(actionTypes.DELETE_USER):
      return {
        ...state,
        adminUsers: data
      }
    case successState(actionTypes.ADD_USER):
      return {
        ...state,
        adminUsers: extract(data.users, []),
        password: extract(data.password, '')
      }
    case successState(actionTypes.RESET_PASSWORD):
      return {
        ...state,
        password: data.password
      }
    case successState(actionTypes.CLEAR_USERS):
      return initialState
    default:
      return state || initialState
  }
}

export default usersReducer
