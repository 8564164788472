import React from 'react'
import { Avatar, Button } from 'antd'
import moment from 'moment'

const dateFormat = 'DD/MM/YYYY'

const SurveyHeader = ({ assignment, onClick }) => {

  const color = '#9368B7'
  return ( (assignment && assignment.name)?
    <div>
      <div className='gx-profile-banner'>
        <div className='gx-profile-container'>
          <div className='gx-profile-banner-top'>
            <div className='gx-profile-banner-top-left gx-pl-5 gx-pt-5'>
              <div className='gx-profile-banner-avatar'>
                <Avatar className='gx-size-90 gx-fs-2xl' alt='...' style={{ backgroundColor: color, verticalAlign: 'middle', marginRight: 15 }} size='large'>
                  {assignment?.name[0].toUpperCase()}
                </Avatar>
              </div>
              <div className='gx-profile-banner-avatar-info' style={{maxWidth: '390px'}}>
                <h2 className='gx-mb-0 gx-fs-xxl gx-font-weight-light'>#{assignment?.id} </h2>
                <div style={{display: 'flex'}} ><h3>{assignment?.name}</h3> <div style={{width:'10px'}} />|<div style={{width:'10px'}} /><h3>{assignment?.position}</h3></div>
                <p className='gx-mb-0 gx-fs-lg'>Ingreso: {moment(assignment?.startDate).format(dateFormat)} - Baja: {moment(assignment?.endDate).format(dateFormat)}</p>
                <hr style={{backgroundColor: 'white'}}/>
                <span style={{fontSize: '16px', fontStyle: 'italic'}}>{assignment?.comment?assignment.comment:'Sin Comentario'}</span>
              </div>
            </div>
            <div className='gx-profile-banner-top-right gx-pr-5' style={{marginBottom: '20px!important'}}>
              <ul className='gx-follower-list gx-pt-5'>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '20px'}}>
                  <span className='gx-follower-title gx-fs-2xl gx-font-weight-medium'>
                    <i className={'gx-icon-btn icon icon-phone'} />
                  </span>
                  <span className='gx-follower-title gx-fs-2xl gx-font-weight-medium'>
                    {assignment?.contactPhone}
                  </span>
                </div>
                <li>
                  <span className='gx-fs-lg'>
                    <br/>
                    <Button onClick={onClick}>Sin Respuesta</Button>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>:<></>
  )
}

export default SurveyHeader
