import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Col, Form, Pagination, Row, Select, Tooltip, Popover, Modal } from 'antd'
import CandidateCell from '../../components/Candidate/CandidateCell'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import '../../styles/pages/candidates.less'
import { useDispatch, useSelector } from 'react-redux'
import { updateTopBarTitle } from '../../store/settings/settings.actions'
import CustomSearch from '../../components/CustomSearch/CustomSearch'
import { selectCandidates } from '../../store/candidates/candidates.selector'
import { reassignCandidate, searchCandidates } from '../../store/candidates/candidates.actions'
import { UserOutlined } from '@ant-design/icons'
import { selectIsAdmin, selectIsSupervisor } from '../../store/auth/auth.selectors'
import { selectUsers } from '../../store/users/users.selector'
import { getUsers } from '../../store/users/users.actions'
import { clearActionResult } from '../../store/app/app.actions'
import { actionTypes } from '../../store/candidates/candidates.types'
import AssignCandidateForm from '../../components/Candidate/AssignCandidateForm'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { useHasErrors } from '../../store/app/error/error.hooks'
import { UNEXPECTED_OOPS } from '../../util/messages.utils'
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator'
import { searchCompanies } from '../../store/company/company.actions'

const { Option } = Select
const { error, success } = Modal

const ProcessCandidates = () => {
  const candidates = useSelector(selectCandidates)
  const data = candidates.items || []
  const dispatch = useDispatch()
  const [searchForm] = Form.useForm()
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 })
  const [user, setUser] = useState(0)
  const isAdmin = useSelector(selectIsAdmin)
  const isSupervisor = useSelector(selectIsSupervisor)
  const access = useMemo(() => isAdmin || isSupervisor, [isAdmin, isSupervisor])
  const users = useSelector(selectUsers)
  const [formReassign] = Form.useForm()
  const [showAssignmentModal, setAssignmentModal] = useState(false)
  const [candidate, setCandidate] = useState(null)

  const toggleAssignmentModal = useCallback((user) => {
    setCandidate(user)
    setAssignmentModal(!showAssignmentModal)
  }, [showAssignmentModal])

  const handleReassign = ({ userId }) => {
    dispatch(reassignCandidate({ candidateId: candidate.id, userId }))
  }

  const refresh = useCallback(({ search, last, from, to } = {}) => {
    dispatch(searchCandidates({
      status: 'ENVIADO',
      search,
      sortOrder: 'ASC',
      sortProperty: 'sentDate',
      searchProperty: 'name',
      investigating: true,
      pending: false,
      last,
      from: from?.unix(),
      to: to?.unix(),
      ...pagination,
      byUser: access ? user : null
    }))
  }, [access, dispatch, pagination, user])

  const init = () => {
    dispatch(updateTopBarTitle('Investigaciones en proceso'))
    dispatch(searchCompanies({ pageSize: 100000 }))
    if (access) {
      dispatch(getUsers())
    }
    return () => dispatch(clearActionResult(actionTypes.SEARCH_CANDIDATES))
  }

  const [assignIsLoading, assignFinished] = useIsLoading([actionTypes.REASSIGN_CANDIDATE])
  const [assignError, assignHasError] = useHasErrors([actionTypes.REASSIGN_CANDIDATE])
  useEffect(() => {
    if (assignFinished) {
      if (assignHasError) {
        error({
          title: '¡Uh-oh!',
          content: assignError.message || UNEXPECTED_OOPS
        })
      } else {
        success({
          title: '¡Éxito!',
          content: 'Encuesta creada con éxito'
        })
      }
      toggleAssignmentModal(null)
      refresh()
    }
  }, [assignError.message, assignFinished, assignHasError, refresh, toggleAssignmentModal])

  const onPageChange = (page, pageSize) => {
    setPagination({ page, pageSize })
    searchForm.getFieldsValue()
  }

  const getUserFilters = () => {
    return (
      <Row className='gx-p-2'>
        <Col>
          <Select style={{ width: 200 }} showSearch optionFilterProp='children' value={user} onChange={setUser}>
            <Option value={0}>Todos</Option>
            {
              users.map((user) =>
                <Option key={`users-${user.id}`} value={user.id}>{user.name}</Option>
              )
            }
          </Select>
        </Col>
      </Row>
    )
  }

  useEffect(init, [])
  return (
    <div>
      <LoadingIndicator loading={assignIsLoading} />
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <br />
            <div className='gx-module-box-topbar flex-header'>
              <CustomSearch form={searchForm} onChange={refresh} last={false} dates={false} />
              {
                access &&
                  <Row justify='end' style={{ marginLeft: 20, paddingTop: 5, marginRight: 5 }}>
                    <Tooltip title='Filtrar por usuario'>
                      <Popover content={getUserFilters()} title='Filtrar por usuario' trigger='click' placement='bottomRight'>
                        <Button icon={<UserOutlined />} />
                      </Popover>
                    </Tooltip>
                  </Row>
              }
            </div>
            <CustomScrollbars className='process-scroll'>
              {
                data.length === 0
                  ? (
                    <div className='gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center'>
                      Sin Candidatos
                    </div>
                  )
                  : data.map((candidate) => <CandidateCell handleModal={toggleAssignmentModal} candidate={candidate} key={`${candidate.id}`} />)
              }
            </CustomScrollbars>
            <Row justify='end' className='gx-border-top gx-pr-5 gx-pt-2'>
              <Pagination
                showSizeChanger
                defaultCurrent={1}
                onChange={onPageChange}
                total={candidates.total || 0}
                current={candidates.page || 1}
                pageSize={candidates.pageSize || 10}
                hideOnSinglePage={false}
                showTotal={(total, range) => `${range[0]} a ${range[1]} de ${total}`}
              />
            </Row>
          </div>
        </div>
      </div>
      <Modal
        title={`Reasignar ${candidate?.name} al investigador:`}
        visible={showAssignmentModal}
        footer={[
          <Button
            key='cancelar'
            onClick={toggleAssignmentModal}
          > Cancelar
          </Button>,
          <Button
            key='ok'
            type='primary'
            onClick={() => formReassign.submit()}
          > Aceptar
          </Button>
        ]}
      >
        <Row>
          <Col span={24}>
            <AssignCandidateForm form={formReassign} onFinish={handleReassign} />
          </Col>
        </Row>
      </Modal>
    </div>
  )
}
export default ProcessCandidates
