import React, {useEffect, useState} from 'react'
import {Input} from "antd";

const TextQuestion = ({ question, answers, onChange, steps, index }) => {
  const [answerValue, setAnswerValue] = useState(answers[question.id])

  const init = () => {
    //console.log('rendering ' + JSON.stringify(answers))
  }
  useEffect(init, [])

  return (
    <div style={{padding: '20px 0px'}}>
      <label htmlFor='question' style={{margin: '20px 0px'}}>{question.text}</label>
      <Input
        id='question'
        placeholder='Respuesta'
        style={{margin: '20px 0px'}}
        value={answerValue}
        //disabled={steps[index].noAnswer}
        onChange={(e) => { setAnswerValue(e.target.value); onChange(question.id, e.target.value) }}
      />
    </div>
  )
}

export default TextQuestion
