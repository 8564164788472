import React, {useEffect, useState} from 'react'
import { Checkbox } from 'antd'

const CheckBoxQuestion = ({ question, answers, onChange }) => {
  const [options, setOptions] = useState([])

  const init = () => {
    console.log('rendering ' + JSON.stringify(answers))
    const opts = []
    question.options.map(o => opts.push({ label: o.text, value: o.id }))
    setOptions(opts)
  }
  useEffect(init, [])

  return (
    <div style={{padding: '20px 0px'}}>
      <label htmlFor='questions'>{question.text}</label>
      <div style={{maxHeight: 'calc(100vh - 560px)', overflowY: 'scroll'}}>
        <Checkbox.Group
          style={{ display: 'flex', flexFlow: 'column' }}
          options={options}
          defaultValue={answers[question.id]}
          onChange={(e) => onChange(question.id, e)}
        />
      </div>
    </div>
  )
}

export default CheckBoxQuestion
