export const NOOP = () => {}

const hashCode = (str) => { // java String#hashCode
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

const intToRGB = (i) => {
  const c = (i & 0x00FFFFFF)
    .toString(16)
    .toUpperCase()
  const result = '00000'.substring(0, 6 - c.length) + c
  return '#' + result
}

export const randomHexColorByString = (str) => {
  return intToRGB(hashCode(str))
}
