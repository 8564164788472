export const actionTypes = {
  ADD_COMPANY: 'company/add',
  UPDATE_COMPANY: 'company/update',
  GET_COMPANY: 'company/get',
  TOGGLE_COMPANY: 'company/active/toggle',
  SEARCH_COMPANIES: 'companies/search',
  SEARCH_INVOICES: 'companies/invoices',
  GENERATE_INVOICES: 'companies/invoices/generate',
  DOWNLOAD_INVOICE: 'companies/invoice/download',
  GET_ANSWERS: 'company/asnwers/get',
  ADD_ANSWER: 'company/asnwers/add',
  EDIT_ANSWER: 'company/asnwers/edit',
  REMOVE_ANSWER: 'company/asnwers/remove',
  CLEAR_COMPANY: 'company/asnwers/remove',
  UPDATE_POSITIONS_ANSWERS: 'company/answers/positions'
}
