import React from 'react'
import Sidebar from '../containers/Sidebar'
import { Layout } from 'antd'
import TopBar from '../containers/Topbar'
import { useSelector } from 'react-redux'
import { selectToken } from '../store/auth/auth.selectors'
import { history } from '../store'

const { Content } = Layout

const Auth = ({ Component }) => {
  const token = useSelector(selectToken)

  if (token == null) {
    history.push('/login')
  }

  return (
    <Layout className='gx-app-layout'>
      <Sidebar />
      <Layout>
        <TopBar />
        <Content className='gx-layout-content gx-container-wrap'>
          <div className='gx-main-content-wrapper'>
            <Component />
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}
export default Auth
