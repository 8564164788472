import { $http, downloadFile } from '../../util/http.utils'

class CandidatesService {
  search = ({ page, pageSize, sortOrder, sortProperty, last, from, to, search, searchProperty, status, pending = true, investigating, result = 0, byUser }) =>
    $http.get('/admin/candidates', { params: { page, pageSize, sortOrder, sortProperty, last, from, to, search, searchProperty, status, pending, investigating, result, byUser } })

  byId = ({ candidateId }) =>
    $http.get(`/admin/candidates/${candidateId}`)

  processCandidate = ({ candidateId }) =>
    $http.post(`/admin/candidates/${candidateId}`)

  reassignCandidate =
    ({ candidateId, userId }) =>
      $http.post(`/admin/candidate/${candidateId}/reasign`, { userId })

  getJobs = ({ candidateId }) =>
    $http.get(`/admin/candidates/${candidateId}/jobs`)

  addJob =
    ({ candidateId, company, lada, phone, position, boss, start, end, reason }) =>
      $http.post(`/admin/candidates/${candidateId}/jobs`, { company, lada, phone, position, boss, start, end, reason })

  confirmJob = ({ candidateId, jobId }) =>
    $http.put(`/admin/candidates/${candidateId}/jobs/${jobId}`)

  differJob = ({ candidateId, jobId, comments }) =>
    $http.put(`/admin/candidates/${candidateId}/jobs/${jobId}/differs`, { comments })

  addJudicial = ({ candidateId, text }) =>
    $http.put(`/admin/candidates/${candidateId}/judicial`, { text })

  addSindicatos = ({ candidateId, text }) =>
    $http.put(`/admin/candidates/${candidateId}/sindicatos`, { text })

  addComments = ({ candidateId, text }) =>
    $http.put(`/admin/candidates/${candidateId}/comments`, { text })

  finish = ({ candidateId, result }) =>
    $http.put(`/admin/candidates/${candidateId}/finish/${result}`)

  getReport = ({ candidateId }) =>
    downloadFile(`/candidates/${candidateId}/report`)
}
export default new CandidatesService()
