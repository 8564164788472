import React from 'react'
import { Col, Form, Input, Row } from 'antd'
import { inputRequired, typeEmail } from '../../util/forms.utils'

const CompanyFrom = ({ form, isProcessed, handleFormChanges, formData, current, className, onFinish }) => {
  const InputGroup = Input.Group
  let index = 0

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  }

  return (
    <div className={className}>
      <div style={{ height: '10px' }} />
      <Form
        layout='horizontal'
        form={form}
        name='register'
        labelAlign='left'
        onFinish={onFinish}
        scrollToFirstError
        onValuesChange={handleFormChanges}
        {...formItemLayout}
      >
        <div className={`form--item__company ${current !== 0 ? 'hide' : ''}`}>
          <Form.Item
            name='name'
            label='Nombre'
            rules={[inputRequired]}
            labelCol={{ sm: { span: 4 } }}
            wrapperCol={{ sm: { span: 20 } }}
          >
            <Input className='disabled-input company-input' readOnly={isProcessed} placeholder='Nombre' />
          </Form.Item>

          <Form.Item
            name='address'
            label='Direccion'
            rules={[inputRequired]}
            labelCol={{ sm: { span: 4 } }}
            wrapperCol={{ sm: { span: 20 } }}
          >
            <Input className='disabled-input company-input' readOnly={isProcessed} placeholder='Direccion' />
          </Form.Item>

          <InputGroup>
            <Row>
              <Col sm={12}>
                <Form.Item
                  name='phone'
                  label='Telefono'
                  rules={[inputRequired]}
                >
                  <Input className='company-input' readOnly={isProcessed} placeholder='Telefono de la empresa' />
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item
                  name='email'
                  label='Correo'
                  rules={[typeEmail, inputRequired]}
                >
                  <Input className='company-input' type='email' readOnly={isProcessed} placeholder='email@ejemplo.com' />
                </Form.Item>
              </Col>
            </Row>
          </InputGroup>

          <Form.Item
            name='contact'
            label='Contacto'
            rules={[inputRequired]}
            labelCol={{ sm: { span: 4 } }}
            wrapperCol={{ sm: { span: 20 } }}
          >
            <Input className='disabled-input company-input' readOnly={isProcessed} placeholder='Contacto' />
          </Form.Item>
        </div>
        <div className={`form-item__admin ${current !== 1 ? 'hide' : ''}`}>
          <Form.Item
            name='adminName'
            label='Nombre'
            rules={[inputRequired]}
            labelCol={{ sm: { span: 8 } }}
            wrapperCol={{ sm: { span: 16 } }}
          >
            <Input className='admin-input' placeholder='Nombre' />
          </Form.Item>
          <Form.Item
            name='adminEmail'
            label='Correo'
            rules={[typeEmail, inputRequired]}
            labelCol={{ sm: { span: 8 } }}
            wrapperCol={{ sm: { span: 16 } }}
          >
            <Input className='admin-input' type='email' placeholder='email@ejemplo.com' />
          </Form.Item>
        </div>
        <div className={`form-item__confirm ${current !== 2 ? 'hide' : ''}`}>
          <div className='confirm-content'>
            {
              Object.entries(formData).map(([i, items]) => {
                index++
                return (
                  <div className='confirm-info__item' key={`confirm-item_${index}`}>
                    <div className='confirm-info__title'>
                      {i === 'company'
                        ? (
                          <div className='info-title__item'>
                            <i className='icon icon-company' />
                            <span>Datos de la Empresa</span>
                          </div>
                        )
                        : (
                          <div className='info-title__item'>
                            <i className='icon icon-avatar' />
                            <span>Datos del Administrador</span>
                          </div>
                        )}
                    </div>
                    <div className='confirm-info__list'>
                      {
                        Object.values(items).map(data => {
                          index++
                          return (
                            <div className='confirm-info__item' key={`info-item_${index}`}>
                              <p className='confirm-info__content'>
                                <span>{data.label}: </span>
                                {data.value}
                              </p>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </Form>
    </div>
  )
}

export default CompanyFrom
