import { $http } from '../../util/http.utils'

class UsersService {
  getUsers = () => $http.get('/admin/users')

  checkUserEmail =
    ({ email }) =>
      $http.get('/admin/user/exist', { params: { email } })

  createUserWithEmailAndName =
    ({ email, name }) =>
      $http.post('/admin/user', { email, name })

  setUserAdmin =
    ({ userId }) =>
      $http.put(`/admin/users/admin/${userId}`)

  unsetUserAdmin =
    ({ userId }) =>
      $http.delete(`/admin/users/admin/${userId}`)

  setUserInvestigator =
    ({ userId }) =>
      $http.put(`/admin/users/investigator/${userId}`)

  unsetUserInvestigator =
    ({ userId }) =>
      $http.delete(`/admin/users/investigator/${userId}`)

  setUserSupervisor =
    ({ userId }) =>
      $http.put(`/admin/users/supervisor/${userId}`)

  unsetUserSupervisor =
    ({ userId }) =>
      $http.delete(`/admin/users/supervisor/${userId}`)

  resetPassword =
    ({ userId }) =>
      $http.put(`/admin/${userId}/reset-password`)
}

export default new UsersService()
