import React, { useEffect, useState } from 'react'
import CompanyForm from './CompanyForm'
import { Button, Form, message, Steps, Modal, Typography } from 'antd'
import '../../styles/pages/company.less'
import { useDispatch, useSelector } from 'react-redux'
import { addCompany } from '../../store/company/company.actions'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { actionTypes } from '../../store/company/company.types'
import { useHasErrors } from '../../store/app/error/error.hooks'
import { selectCreatedCompany } from '../../store/company/company.selector'
import { GENERIC_MESSAGE_ERROR } from '../../util/messages.utils'

const { Text } = Typography
const { error, success } = Modal

const steps = [{
  index: 0,
  title: 'Datos de la Empresa',
  type: 'company',
  validate: ['name', 'address', 'phone', 'email', 'contact'],
  isValid: false
}, {
  index: 1,
  title: 'Administrador',
  type: 'admin',
  validate: ['adminName', 'adminEmail'],
  isValid: false
}, {
  index: 2,
  title: 'Confirmación',
  isValid: true
}]

const Step = Steps.Step

const CompanySteps = ({ onFinish }) => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})
  const [current, setCurrent] = useState(steps[0])
  const [loading, finished] = useIsLoading([actionTypes.ADD_COMPANY])
  const [APIError, hasError] = useHasErrors([actionTypes.ADD_COMPANY])
  const created = useSelector(selectCreatedCompany)

  const dispatch = useDispatch()

  const handleFormChanges = (values) => {
    const [input] = Object.entries(values)
    const inputInstance = form.getFieldInstance(input[0])
    const label = inputInstance.input.labels[0].innerHTML
    const type = inputInstance.input.classList.contains('company-input') ? 'company' : 'admin'

    setFormData({
      ...formData,
      [type]: { ...formData[type], [input[0]]: { label: label, value: input[1] } }
    })
  }

  const next = () => {
    form.validateFields(current.validate)
      .then(() => {
        const ncurrent = current.index + 1
        const nextStep = steps[ncurrent]
        setCurrent(nextStep)
      })
      .catch(({ errorFields }) => {
        current.isValid = !Object.keys(errorFields).length
      })
  }

  const prev = () => {
    const ncurrent = current.index - 1
    const currentStep = steps[ncurrent]
    setCurrent(currentStep)
  }

  const submit = ({ name, address, phone, email, contact, adminName, adminEmail }) => {
    dispatch(addCompany({
      name,
      companyEmail: email,
      companyAddress: address,
      companyPhone: phone,
      contactName: contact,
      adminName,
      adminEmail
    }))
  }

  useEffect(() => {
    if (finished) {
      if (hasError) {
        error({
          title: '¡Uh-oh!',
          content: APIError.message || GENERIC_MESSAGE_ERROR
        })
      } else {
        if (created != null) {
          const { user } = created
          form.resetFields()
          setCurrent(steps[0])
          setFormData({})
          onFinish()
          success({
            title: '¡Empresa creada con éxito!',
            content: user.password
              ? <Text>Se creó el usuario administrador, la contraseña es: <Text mark>{user.password}</Text>, se recomienda cambiarla.</Text>
              : 'El usuario administrador ya existia, se han añadido los permisos para la nueva empresa.'
          })
        }
      }
    }
  }, [created, APIError.message, finished, hasError, onFinish, form])

  return (
    <div>
      <Steps current={current.index}>
        {steps.map(item => <Step key={item.title} title={item.title} />)}
      </Steps>
      <div className='steps-content'>
        <CompanyForm form={form} onFinish={submit} formData={formData} handleFormChanges={handleFormChanges} current={current.index} message={message} />
      </div>
      <div className='steps-action'>
        {
          current.index > 0 &&
            <Button style={{ marginLeft: 8 }} onClick={() => prev()}>
              Anterior
            </Button>
        }
        {
          current.index < steps.length - 1 &&
            <Button type='primary' onClick={() => next()}>Siguiente</Button>
        }
        {
          current.index === steps.length - 1 &&
            <Button type='primary' onClick={() => form.submit()} loading={loading}>Finalizar</Button>
        }
      </div>
    </div>
  )
}

export default CompanySteps
