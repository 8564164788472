import React from 'react'
import { Avatar, Tag } from 'antd'
import { useSelector } from 'react-redux'
import { selectCurrentCandidate } from '../../store/candidates/candidates.selector'
import { selectCompanies } from '../../store/company/company.selector'

const getColorByStatus = (status) => {
  switch (status) {
    case 1:
      return '#87D068'
    case 2:
      return '#F3D46E'
    case 3:
      return '#FF5A87'
    default:
      return 'orange'
  }
}

const getTagByStatus = (status) => {
  switch (status) {
    case 1:
      return (
        <Tag color='green' className='status-tag big'>APTO</Tag>
      )
    case 2:
      return (
        <Tag color='orange' className='status-tag big'>APTO CON RESERVAS</Tag>
      )
    case 3:
      return (
        <Tag color='red' className='status-tag big'>NO APTO</Tag>
      )
    default:
      return (
        <Tag color='orange' className='status-tag big'>EN INVESTIGACIÓN</Tag>
      )
  }
}
const CandidateProfileHeader = () => {
  const candidate = useSelector(selectCurrentCandidate)
  const inProcess = !!candidate?.investigationDate
  const isNew = !inProcess
  const color = isNew ? '#1890FF' : getColorByStatus(candidate?.result)
  const companies = useSelector(selectCompanies)

  const getCompany = (companyId) => {
    return companies?.items?.find(c => c.id === companyId)?.name || ''
  }

  return (
    <div>
      <div className='gx-profile-banner'>
        <div className='gx-profile-container'>
          <div className='gx-profile-banner-top'>
            <div className='gx-profile-banner-top-left gx-pl-5 gx-pt-5'>
              <div className='gx-profile-banner-avatar'>
                <Avatar className='gx-size-90 gx-fs-2xl' alt='...' style={{ backgroundColor: color, verticalAlign: 'middle', marginRight: 15 }} size='large'>
                  {candidate?.name[0].toUpperCase()}
                </Avatar>
              </div>
              <div className='gx-profile-banner-avatar-info'>
                <h2 className='gx-mb-0 gx-fs-xxl gx-font-weight-light'>Folio #{candidate?.id} </h2>
                <h3>{candidate?.name}</h3>
                <p className='gx-mb-0 gx-fs-lg'>{candidate?.address}, {candidate?.city}</p>
                <br />
                <p className='gx-mb-0 gx-fs-lg'>Solicitado por: <span className='gx-text-underline'>{getCompany(candidate?.companyId)}</span></p>
              </div>
            </div>
            <div className='gx-profile-banner-top-right gx-pr-5'>
              <ul className='gx-follower-list gx-pt-5'>
                <li>
                  <span className='gx-follower-title gx-fs-2xl gx-font-weight-medium'>{candidate?.jobsCount}</span>
                  <span className='gx-fs-lg'>Empleos</span>
                  <div className='gx-fs-2xl gx-mt-1' style={{ color }}>
                    {getTagByStatus(candidate?.result)}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CandidateProfileHeader
