import React, { useEffect, useState } from 'react'
import Row from 'antd/lib/grid/row'
import Col from 'antd/lib/grid/col'
import Widget from 'components/Widget'
import CandidateProfileHeader from '../../components/Candidate/CandidateProfileHeader'
import { Button, Modal, Tabs, Tag, Typography } from 'antd'
import CandidateForm from '../../components/Candidate/CandidateForm'
import JobList from '../../components/Jobs/JobList'
import TextAreaForm from '../../components/Verification/TextAreaForm'
import JobData from '../../components/Jobs/JobData'
import { useDispatch, useSelector } from 'react-redux'
import { selectCandidateJobs, selectCurrentCandidate } from '../../store/candidates/candidates.selector'
import {
  addComments,
  addJudicial,
  addSindicatos,
  finishCandidate,
  getCandidateJobs,
  singleCandidate
} from '../../store/candidates/candidates.actions'
import { useParams } from 'react-router-dom'
import { useIsLoading } from '../../store/app/loading/loading.hooks'
import { actionTypes } from '../../store/candidates/candidates.types'
import { selectCurrentUser } from '../../store/auth/auth.selectors'
import { NOOP } from '../../util/functions.utils'
import { history } from '../../store'
import {
  selectCommentsAnswers,
  selectJudicialAnswers,
  selectSindicatoAnswers
} from '../../store/company/company.selector'
import { getAutomaticAnswers } from '../../store/company/company.actions'

const { TabPane } = Tabs
const { Text } = Typography
const { success, confirm } = Modal

const Jobs = () => {
  const { candidateId } = useParams()
  const candidate = useSelector(selectCurrentCandidate)
  const dispatch = useDispatch()
  const [selected, setSelected] = useState(0)
  const [job, setJob] = useState(null)
  const currentUser = useSelector(selectCurrentUser)
  const [, finished] = useIsLoading([actionTypes.ADD_JUDICIAL, actionTypes.ADD_SINDICATOS, actionTypes.ADD_COMMENTS])
  const [, processFinished] = useIsLoading([actionTypes.FINISH_CANDIDATE])
  const processed = !!candidate?.finishDate
  const showButton = !processed || currentUser?.id === 1

  const judicial = useSelector(selectJudicialAnswers)
  const sindicato = useSelector(selectSindicatoAnswers)
  const comments = useSelector(selectCommentsAnswers)
  const jobs = useSelector(selectCandidateJobs)

  const isSelected = (id, color) => {
    if (id === selected) {
      return color
    }
    return ''
  }

  const init = () => {
    dispatch(singleCandidate({ candidateId }))
    dispatch(getCandidateJobs({ candidateId }))
    dispatch(getAutomaticAnswers())
  }
  useEffect(init, [])

  useEffect(() => {
    setSelected(candidate?.result || 0)
  }, [candidate])

  const onJobSelected = record => {
    setJob(record)
  }

  useEffect(() => {
    const found = jobs.find((item) => item.id === job?.id)
    setJob(found)
  }, [job, jobs])

  const saveJudicial = (text) => {
    if (text) {
      if (processed) {
        confirm({
          title: '¡Cuidado!',
          content: 'Este candidato ya terminó el proceso de investigación, ¿Está seguro que desea editarlo?',
          onOk: () => dispatch(addJudicial({ candidateId: candidate.id, text }))
        })
      } else {
        dispatch(addJudicial({ candidateId: candidate.id, text }))
      }
    }
  }

  const saveSindicatos = (text) => {
    if (text) {
      if (processed) {
        confirm({
          title: '¡Cuidado!',
          content: 'Este candidato ya terminó el proceso de investigación, ¿Está seguro que desea editarlo?',
          onOk: () => dispatch(addSindicatos({ candidateId: candidate.id, text }))
        })
      } else {
        dispatch(addSindicatos({ candidateId: candidate.id, text }))
      }
    }
  }

  const saveComments = (text) => {
    if (text) {
      if (processed) {
        confirm({
          title: '¡Cuidado!',
          content: 'Este candidato ya terminó el proceso de investigación, ¿Está seguro que desea editarlo?',
          onOk: () => dispatch(addComments({ candidateId: candidate.id, text }))
        })
      } else {
        dispatch(addComments({ candidateId: candidate.id, text }))
      }
    }
  }

  const finish = () => {
    if (processed) {
      confirm({
        title: '¡Cuidado!',
        content: 'Este candidato ya terminó el proceso de investigación, ¿Está seguro que desea editarlo?',
        onOk: () => dispatch(finishCandidate({ candidateId: candidate.id, result: selected }))
      })
    } else {
      dispatch(finishCandidate({ candidateId: candidate.id, result: selected }))
    }
  }

  useEffect(() => {
    if (processFinished) {
      success({
        title: '¡Exito!',
        content: 'Cambios guardados exitosamente',
        onOk: () => history.push('/in-process')
      })
    }
  }, [processFinished])

  useEffect(() => {
    if (finished) {
      success({
        title: '¡Exito!',
        content: 'Cambios guardados exitosamente'
      })
    }
  }, [finished])

  return (
    <div>
      <CandidateProfileHeader />
      <div className='gx-profile-content'>
        <Widget title='Verificación' styleName='gx-card-tabs gx-card-tabs-right gx-card-profile'>
          <Tabs defaultActiveKey='1'>
            <TabPane tab='Informacion Basica' key='1'>
              <div className='gx-mb-2'>
                <CandidateForm candidate={candidate} />
              </div>
            </TabPane>
            <TabPane tab='Empleos' key='2'>
              <div className='gx-mb-2'>
                <Row align='start'>
                  <Col span={10} style={{ minHeight: 250, marginTop: 0 }}>
                    <JobList onClick={onJobSelected} showButton={showButton} />
                  </Col>

                  <Col span={14} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '0 auto' }}>
                    <JobData job={job || {}} noButton={!showButton} />
                  </Col>
                </Row>
              </div>
            </TabPane>
            <TabPane tab='Investigacion Judicial' key='3'>
              <div className='gx-mb-2'>
                <Row>
                  <Col span={1} />
                  <Col span={22}>
                    <TextAreaForm
                      answers={judicial}
                      value={candidate?.judicial}
                      onSave={saveJudicial} noButton={!showButton}
                    />
                  </Col>
                  <Col span={1} />
                </Row>
              </div>
            </TabPane>
            <TabPane tab='Sindicatos' key='4'>
              <div className='gx-mb-2'>
                <Row>
                  <Col span={1} />
                  <Col span={22}>
                    <TextAreaForm
                      answers={sindicato}
                      value={candidate?.sindicato}
                      onSave={saveSindicatos} noButton={!showButton}
                    />
                  </Col>
                  <Col span={1} />
                </Row>
              </div>
            </TabPane>
            {/* <TabPane tab='Empleos IMSS' key='5'> */}
            {/*  <div className='gx-mb-2'> */}
            {/*    <Row> */}
            {/*      <Col span={12}> */}
            {/*        <IMSSJobData /> */}
            {/*      </Col> */}

            {/*      <Col span={12}> */}
            {/*        <IMSSJobForm /> */}
            {/*      </Col> */}
            {/*    </Row> */}
            {/*  </div> */}
            {/* </TabPane> */}
            <TabPane tab='Recomendacion' key='6'>
              <div className='gx-mb-2'>
                <Row>
                  <Col span={1} />
                  <Col span={22}>
                    <TextAreaForm
                      answers={comments}
                      onSave={saveComments}
                      value={candidate?.comments}
                      noButton={!showButton}
                    />
                  </Col>
                  <Col span={1} />
                </Row>
              </div>
            </TabPane>
            <TabPane tab='Finalizar' key='7'>
              <Row align='middle'>
                <Col span={10} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                  <Tag
                    color={isSelected(1, '#87D068')} className='clickable-status-tag xl'
                    onClick={!showButton ? NOOP : () => selected === 1 ? setSelected(0) : setSelected(1)}
                  >
                    APTO
                  </Tag>
                  <Tag
                    color={isSelected(2, '#F3D46E')} className='clickable-status-tag xl cr'
                    onClick={!showButton ? NOOP : () => selected === 2 ? setSelected(0) : setSelected(2)}
                  >
                    APTO <br />CON RESERVA
                  </Tag>
                  <Tag
                    color={isSelected(3, '#FF5A87')} className='clickable-status-tag xl'
                    onClick={!showButton ? NOOP : () => selected === 3 ? setSelected(0) : setSelected(3)}
                  >
                    NO APTO
                  </Tag>
                </Col>
              </Row>
              <br />
              <Row align='middle' justify='center'>
                {
                  showButton
                    ? (
                      <Text>
                        Para finalizar el candidato debe seleccionar un resultado y haber guardado el texto de recomendación
                      </Text>
                    )
                    : <Text>Este candidato ya ha finalizado el proceso de investigación</Text>
                }
              </Row>
              <br />
              {
                showButton &&
                  <Row align='middle' justify='center'>
                    <Button
                      type='primary' key='Guardar' onClick={finish}
                      disabled={!selected || !candidate?.comments}
                    >
                      Finalizar
                    </Button>
                  </Row>
              }
            </TabPane>
          </Tabs>
        </Widget>
      </div>
    </div>
  )
}

export default Jobs
