import { $http, downloadFile } from '../../util/http.utils'

class CompanyService {
  search = ({ active, deleted, last, page, pageSize, search, searchProperty, sortOrder, sortProperty }) =>
    $http.get('/company', { params: { active, deleted, last, page, pageSize, search, searchProperty, sortOrder, sortProperty } })

  getCompany = ({ id }) =>
    $http.get(`/admin/company/${id}`)

  create =
    ({ name, companyEmail, companyAddress, companyPhone, contactName, adminEmail, adminName }) =>
      $http.post('/company', { name, companyEmail, companyAddress, companyPhone, contactName, adminEmail, adminName })

  update =
    ({ companyId, address, contactName, email, name, phone }) =>
      $http.put(`/admin/company/${companyId}`, { address, contactName, email, name, phone })

  activate = ({ companyId }) =>
    $http.put(`/admin/company/${companyId}/active`)

  deactivate = ({ companyId }) =>
    $http.delete(`/admin/company/${companyId}/active`)

  searchInvoices = ({ companyId, active, deleted, last, page, pageSize, search, searchProperty, sortOrder, sortProperty, from, to }) =>
    $http.get(`/admin/company/${companyId}/invoice`, { params: { active, deleted, last, page, pageSize, search, searchProperty, sortOrder, sortProperty, from, to } })

  generateInvoice = ({ companyId }) =>
    $http.post(`/admin/company/${companyId}/invoice`)

  downloadInvoice = ({ companyId, invoiceId }) => downloadFile(`/admin/company/${companyId}/invoice/${invoiceId}/download`)

  getAutomaticAnswers = () => $http.get('/admin/answers')

  addAutomaticAnswer = ({ text, category }) => $http.post('/admin/answers', { text, category })

  editAutomaticAnswer = ({ answerId, text }) => $http.put(`/admin/answers/${answerId}`, { text })

  removeAutomaticAnswer = ({ answerId }) => $http.delete(`/admin/answers/${answerId}`)

  updatePositions = (list) => $http.post('/admin/answers/positions', list)
}

export default new CompanyService()
