import React, { useState } from 'react'
import { Avatar, Tooltip } from 'antd'
import { randomHexColorByString } from '../../util/functions.utils'
import Row from 'antd/es/grid/row'
import Col from 'antd/es/grid/col'
import { Link } from 'react-router-dom'

const BranchCell = (props) => {

  const [branch] = useState(props.branch)

  return (
    <div className='gx-contact-item'>
      <div className='gx-module-list-icon'>
        <div className='gx-ml-2 gx-d-none gx-d-sm-flex'>
          <Avatar style={{ backgroundColor: randomHexColorByString(branch.name), verticalAlign: 'middle' }} size='large'>
            {branch.name[0].toUpperCase()}
          </Avatar>
        </div>
      </div>
      <div className='gx-module-list-info gx-contact-list-info'>
        <div className='gx-module-contact-content'>
          <p className="gx-mb-1">
            <span className="gx-text-truncate gx-contact-name">{branch.name} </span>
          </p>

          <div className="gx-text-muted">
            <span className="gx-email-block gx-mr-2">
              {branch.address}
            </span>
            <span className="gx-toolbar-separator">&nbsp;</span>
            <span className="gx-email-block gx-mr-2">
              {branch.phone}
            </span>
          </div>
        </div>
      </div>
      <div className='gx-module-contact-right'>
        <Row>
          <Col span={8}>
            <Tooltip title='Eliminar' onClick={() => { props.onDelete(branch.id, branch.name) }}>
              <i className='gx-icon-btn icon icon-trash' />
            </Tooltip>
          </Col>
          <Col span={8}>
            <Tooltip title='Editar'>
              <Link to='/branches/branch'><i className='gx-icon-btn icon icon-edit' /></Link>
            </Tooltip>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BranchCell
