import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Auth from './Auth'
import E404 from '../pages/Error/E404'
import Login from '../pages/Auth/Login'
import DraftCandidates from '../pages/Candidates/DraftCandidates'
import ProcessCandidates from '../pages/Candidates/ProcessCandidates'
import CompletedCandidates from '../pages/Candidates/CompletedCandidates'
import SingleCandidate from '../pages/Candidates/SingleCandidate'
import Users from '../pages/Users/Users'
import Branches from '../pages/Branches/Branches'
import SingleBranch from '../pages/Branches/SingleBranch'
import Zones from '../pages/Zones/Zones'
import UserProfile from '../pages/Users/UserProfile'
import SingleZone from '../pages/Zones/SingleZone'
import Jobs from '../pages/Jobs/Jobs'
import Companies from '../pages/Companies/Companies'
import SingleCompany from '../pages/Companies/SingleCompany'
import Configuration from '../pages/Configuration/Configuration'
import Assignments from "../pages/Surveys/Assignments";
import ProcessAssignment from "../pages/Surveys/ProcessAssignment";
import ProcessAssignments from "../pages/Surveys/ProcessAssignments";
import CompletedAssignments from "../pages/Surveys/CompletedAssignments";
import AssignmentResult from "../pages/Surveys/AssignmentResult";

const Routes = () => {
  return (
    <Switch>

      <Route path='/zones/zone'>
        <Auth Component={SingleZone} />
      </Route>

      <Route path='/zones'>
        <Auth Component={Zones} />
      </Route>

      <Route path='/users'>
        <Auth Component={Users} />
      </Route>

      <Route path='/profile'>
        <Auth Component={UserProfile} />
      </Route>

      <Route path='/login' component={Login} />

      <Route path='/in-process/:candidateId'>
        <Auth Component={Jobs} />
      </Route>

      <Route path='/in-process'>
        <Auth Component={ProcessCandidates} />
      </Route>

      <Route path='/finished'>
        <Auth Component={CompletedCandidates} />
      </Route>

      <Route path='/drafts/candidate'>
        <Auth Component={SingleCandidate} />
      </Route>

      <Route path='/new'>
        <Auth Component={DraftCandidates} />
      </Route>

      <Route path='/companies'>
        <Auth Component={Companies} />
      </Route>

      <Route exact path='/company/:companyId'>
        <Auth Component={SingleCompany} />
      </Route>

      <Route path='/branches/branch'>
        <Auth Component={SingleBranch} />
      </Route>

      <Route path='/branches'>
        <Auth Component={Branches} />
      </Route>

      <Route path='/config'>
        <Auth Component={Configuration} />
      </Route>

      <Route path='/assignments/process/:assignmentId'>
        <Auth Component={ProcessAssignment} />
      </Route>

      <Route path='/assignments/process'>
        <Auth Component={ProcessAssignments} />
      </Route>

      <Route path='/assignments/completed/:assignmentId'>
        <Auth Component={AssignmentResult} />
      </Route>

      <Route path='/assignments/completed'>
        <Auth Component={CompletedAssignments} />
      </Route>

      <Route path='/assignments'>
        <Auth Component={Assignments} />
      </Route>

      <Route path='/' exact>
        <Auth Component={DraftCandidates} />
      </Route>

      <Route component={E404} />
    </Switch>
  )
}
export default Routes
