
import { executeAction } from '../app/app.actions'
import { actionTypes } from './assignments.types'
import AssignmentsService from './assignments.service'
import { history } from '../index'

export const searchAssignment = ({ page, pageSize, sortOrder, sortProperty, last, from, to, search, searchProperty, status, pending = true, investigating, result = 0, byUser }) => dispatch => {
  const process = () => AssignmentsService.search({ page, pageSize, sortOrder, sortProperty, last, from, to, search, searchProperty, status, pending, investigating, result, byUser })
  dispatch(executeAction(actionTypes.SEARCH_ASSIGNMENT, process))
}

export const singleAssignment = ({ assignmentId }) => dispatch => {
  const process = () => AssignmentsService.byId({ assignmentId })
  dispatch(executeAction(actionTypes.SINGLE_ASSIGNMENT, process))
}

export const reassignAssignment = ({ assignmentId, userId }) => dispatch => {
  const process = () => AssignmentsService.reassignAssignment({ assignmentId, userId })
  dispatch(executeAction(actionTypes.REASSIGN_ASSIGNMENT, process))
}

export const processAssignment = ({ assignmentId }) => dispatch => {
  const process = async () => {
    const result = await AssignmentsService.processAssignment({ assignmentId })
    history.push(`/assignments/process/${assignmentId}`)
    return result
  }
  dispatch(executeAction(actionTypes.PROCESS_ASSIGNMENT, process))
}

export const finishAssignment = ({ assignmentId, bulkAnswerRequest }) => dispatch => {
  const process = () => AssignmentsService.finish({ assignmentId, bulkAnswerRequest })
  dispatch(executeAction(actionTypes.FINISH_ASSIGNMENT, process))
}

export const answerAssignment = ({ assignmentId, bulkAnswerRequest }) => dispatch => {
  const process = () => AssignmentsService.answer({ assignmentId, bulkAnswerRequest })
  dispatch(executeAction(actionTypes.FINISH_ASSIGNMENT, process))
}

export const getSurvey = ({ assignmentId }) => dispatch => {
  const process = () => AssignmentsService.getSurvey({ assignmentId })
  dispatch(executeAction(actionTypes.GET_SURVEY, process))
}

export const getAssignmentAnswers = ({ assignmentId }) => dispatch => { // getAssignmentAnswers
  const process = () => AssignmentsService.getAnswers({ assignmentId })
  dispatch(executeAction(actionTypes.ASSIGNMENT_ANSWERS, process))
}

export const downloadAssignmentReport = ({ assignmentId }) => dispatch => {
  const process = () => AssignmentsService.getReport({ assignmentId })
  dispatch(executeAction(actionTypes.ASSIGNMENT_REPORT, process))
}

export const clearAssignments = () => dispatch => {
  dispatch(executeAction(actionTypes.CLEAR_ASSIGNMENT))
}
