import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from '../../constants/theme.settings'
import { Tooltip } from 'antd'

const SidebarLogo = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { width } = useSelector(({ settings }) => settings)
  let navStyle = useSelector(({ settings }) => settings.navStyle)
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER
  }
  return (
    <div className='gx-layout-sider-header'>
      {
        (navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR)
          ? (
            <div className='gx-linebar'>
              <Tooltip title={sidebarCollapsed ? 'Abrir Menú' : 'Cerrar Menú'} placement='bottomRight'>
                <i onClick={() => setSidebarCollapsed(!sidebarCollapsed)}>
                  <img src='/RHG-circle.svg' alt='logo' style={{ height: 40, cursor: 'pointer' }} />
                </i>
              </Tooltip>
            </div>
          )
          : null
      }

      <Link to='/' className='gx-site-logo'>
        <img src='/RHG-text.svg' alt='logo' style={{ width: 100 }} />
      </Link>
    </div>
  )
}

export default SidebarLogo
