import { extract, successState } from '../app/app.actions'
import { actionTypes } from "./assignments.types"

const initialState = {
  assignments: { page: 1, items: [], pageSize: 10, total: 0, count: 0 },
  assignment: null,
  survey: null,
  assignmentAnswers: null
}

const AssignmentsReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case successState(actionTypes.GET_SURVEY): {
      return {
        ...state,
        survey: extract(data, initialState.survey)
      }
    }
    case successState(actionTypes.SEARCH_ASSIGNMENT): {
      return {
        ...state,
        assignments: extract(data, initialState.assignments)
      }
    }
    case successState(actionTypes.ASSIGNMENT_ANSWERS): {
      return {
        ...state,
        assignmentAnswers: extract(data, initialState.assignmentAnswers)
      }
    }
    case successState(actionTypes.SINGLE_ASSIGNMENT):
    case successState(actionTypes.FINISH_ASSIGNMENT):
    case successState(actionTypes.PROCESS_ASSIGNMENT): {
      return {
        ...state,
        assignment: data
      }
    }
    case successState(actionTypes.CLEAR_ASSIGNMENT):
      return initialState
    default:
      return state || initialState
  }
}

export default AssignmentsReducer
