import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateTopBarTitle } from '../../store/settings/settings.actions'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import { Tabs } from 'antd'
import TextAreaForm from '../../components/Verification/TextAreaForm'
import { addAutomaticAnswer, getAutomaticAnswers } from '../../store/company/company.actions'
import {
  selectCommentsAnswers, selectJobCommentsAnswers,
  selectJudicialAnswers,
  selectSindicatoAnswers, selectSurveyCommentsAnswers
} from '../../store/company/company.selector'

const { TabPane } = Tabs

const Configuration = () => {
  const dispatch = useDispatch()
  const judicial = useSelector(selectJudicialAnswers)
  const sindicato = useSelector(selectSindicatoAnswers)
  const comments = useSelector(selectCommentsAnswers)
  const jobComments = useSelector(selectJobCommentsAnswers)
  const surveyComments = useSelector(selectSurveyCommentsAnswers)

  const init = () => {
    dispatch(updateTopBarTitle('Configuración'))
    dispatch(getAutomaticAnswers())
  }
  useEffect(init, [])

  const addJudicialAnswer = (text) => {
    dispatch(addAutomaticAnswer({ text, category: 'judicial' }))
  }
  const addSindicatoAnswer = (text) => {
    dispatch(addAutomaticAnswer({ text, category: 'sindicato' }))
  }
  const addCommentsAnswer = (text) => {
    dispatch(addAutomaticAnswer({ text, category: 'comments' }))
  }
  const addJobCommentsAnswer = (text) => {
    dispatch(addAutomaticAnswer({ text, category: 'jobs' }))
  }
  const addSurveyCommentsAnswer = (text) => {
    dispatch(addAutomaticAnswer({ text, category: 'survey' }))
  }

  return (
    <div className='gx-main-content'>
      <div className='gx-app-module'>
        <div className='gx-module-box-content'>
          <CustomScrollbars className='candidate-tabs-scroll'>
            <div style={{ padding: '28px' }}>
              <Tabs type='card'>
                <TabPane tab='Investigación Judicial' key='1'>
                  <TextAreaForm label='Nueva respuesta automática' answers={judicial} onSave={addJudicialAnswer} editable />
                </TabPane>
                <TabPane tab='Sindicatos' key='2'>
                  <TextAreaForm label='Nueva respuesta automática' answers={sindicato} onSave={addSindicatoAnswer} editable />
                </TabPane>
                <TabPane tab='Recomendaciones' key='3'>
                  <TextAreaForm label='Nueva respuesta automática' answers={comments} onSave={addCommentsAnswer} editable />
                </TabPane>
                <TabPane tab='Empleos' key='4'>
                  <TextAreaForm label='Nueva respuesta automática' answers={jobComments} onSave={addJobCommentsAnswer} editable />
                </TabPane>
                <TabPane tab='Encuestas' key='5'>
                  <TextAreaForm label='Nueva respuesta automática' answers={surveyComments} onSave={addSurveyCommentsAnswer} editable />
                </TabPane>
              </Tabs>
            </div>
          </CustomScrollbars>
        </div>
      </div>
    </div>
  )
}
export default Configuration
