import React, {useEffect, useState} from 'react'
import {Radio} from "antd";

const RadioQuestion = ({question, answers, onChange}) => {
  const [answerValue, setAnswerValue] = useState(answers[question.id])
  const init = () => {
    console.log('rendering ' + JSON.stringify(answers))
  }
  useEffect(init, [])
  return (
    <div style={{padding: '20px 0px'}}>
      <label htmlFor='questions'>{question.text}</label>
      <div style={{maxHeight: 'calc(100vh - 560px)', overflowY: 'scroll'}}>
        <Radio.Group
          onChange={(e) => { setAnswerValue(e.target.value); onChange(question.id, e.target.value) }}
          value={answerValue}
          style={{ display: 'flex', flexFlow: 'column' }}
          id='questions'
        >
          {question?.options &&
          question.options.map(o => <Radio key={o.id} style={{ margin: '5px' }} value={o.id}>{o.text}</Radio>)
          }
        </Radio.Group>
      </div>
    </div>
  )
}

export default RadioQuestion
