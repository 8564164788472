import React, { useState } from 'react'
import CustomScrollbars from '../../components/Scrolls/CustomScrollbars'
import '../../styles/pages/candidates.less'
import { Button, Col, Input, Modal, notification, Pagination, Row } from 'antd'
import ZoneCell from '../../components/Zone/ZoneCell'
import ZoneForm from '../../components/Zone/ZoneForm'

const staticData = [
  {
    id: 1,
    name: 'Zona Norte',
    branches: []
  },
  {
    id: 2,
    name: 'Zona Oeste',
    branches: []
  },
  {
    id: 3,
    name: 'Zona Sur',
    branches: []
  },
  {
    id: 4,
    name: 'Zona Este',
    branches: []
  }
]

const staticBranches = [
  {
    key: '1',
    name: 'Copacabana',
    address: '5950 12700 Montevideo',
    phone: '9750 7700',
    mail: 'AndyBarelaAmador@gustr.com',
    contact: 'Andy Barela Amador'
  },
  {
    key: '2',
    name: 'Ceibo',
    address: '6546 16400 Cuchilla Alta',
    phone: '9655 0919',
    mail: 'BrutusNajeraValadez@superrito.com',
    contact: 'Brutus Najera Valadez'
  },
  {
    key: '3',
    name: 'Republica',
    address: '5246 97002 Blanquillo',
    phone: '9379 8325',
    mail: 'AlciraTorresMoreno@gustr.com\n',
    contact: 'Alcira Torres Moreno'
  }
]

const confirm = Modal.confirm

const Zones = () => {

  const [data, setData] = useState(staticData)
  const [showModal, setShowModal] = useState(false)

  const toggleModal = () => { setShowModal(!showModal) }

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description
    })
  }

  const showDeleteConfirm = (id, name) => {
    confirm({
      title: 'Desea Eliminar esta Zona?',
      content: '',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk () {
        deleteCandidate(id)
        openNotificationWithIcon('info', 'Zona' + name + ' Eliminada', '')
      },
      onCancel () {
        console.log('Cancel')
      }
    })
  }

  const deleteCandidate = (folio) => {
    const newData = [...data]
    const index = newData.map((e) => { return e.id }).indexOf(folio)
    console.log(index)
    if (index >= 0) {
      newData.splice(index, 1)
    }
    setData([...newData])
    console.log(data)
  }

  return (
    <div>
      <div className='gx-main-content'>
        <div className='gx-app-module'>
          <div className='gx-module-box-content'>
            <br />
            <div className='gx-module-box-topbar flex-header'>
              <div style={{ flexGrow: 1 }}>
                <div>
                  <span className='gx-search-icon gx-pointer'><i className='icon icon-search' /></span>
                  <Input style={{ width: '50%', marginLeft: '10px' }} placeholder='Buscar' type='search' />
                </div>
              </div>
              <div className='separation-search-bar'>
                <Button className='gx-btn-block ant-btn green-button flex-color-button' style={{ maxWidth: '150px' }} type='primary' aria-label='add' onClick={() => { toggleModal() }}>
                  <i className='icon icon-add gx-mr-2 flex-icon-button-7' />
                  <span>Agregar Zona</span>
                </Button>
              </div>
            </div>
            {
              data.length > 0 ?
                <div>
                  <CustomScrollbars className='zones-scroll'>
                    {data.map((zone, index) => <ZoneCell key={zone.id} zone={zone} onDelete={showDeleteConfirm} onEdit={toggleModal} />)}
                  </CustomScrollbars>
                  <Row>
                    <Col span={10} />
                    <Col>
                      <br />
                      <Pagination defaultCurrent={1} total={50} />
                    </Col>
                    <Col span={8} />
                  </Row>
                </div>
                :
                <CustomScrollbars className='zones-empty-scroll'>
                  <div className='gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center'>
                    Sin Zonas
                  </div>
                </CustomScrollbars>
            }
          </div>
        </div>
      </div>
      <Modal
        centered
        width={600}
        title='Agregar Zona'
        visible={showModal}
        className='warning-modal'
        onCancel={toggleModal}
        footer={[
          <Button
            key='Cancelar'
            onClick={toggleModal}
          > Cancelar
          </Button>,
          <Button
            key='Registrar'
            type='primary'
            onClick={() => { toggleModal() }}
          >
            Registrar
          </Button>
        ]}
      >
        <ZoneForm branches={staticBranches} />
      </Modal>
    </div>
  )
}

export default Zones
